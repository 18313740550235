import { z } from 'zod';
import { annotatedPbsItemSchema } from './pbsApiEntities';

/**
 * Schema for a medication entity (PBS or non-PBS).
 *
 * TODO: Consider a separate firestore document schema that includes createdAt and updatedAt fields.
 */
export const medicationSchema = z.object({
  /**
   * Unique identifier for the medication (firestore document ID, or UUID?).
   */
  id: z.string(),
  /**
   * Active ingredient(s) of the medication. For multiple active ingredients, they are separated by
   * an plus sign with spaces (' + '), e.g. bimatoprost + timolol.
   *
   * Equivalent to 'drug_name' in PBS details.
   */
  activeIngredient: z.string(),
  /**
   * Brand name of the medication.
   *
   * Equivalent to 'brand_name' in PBS details.
   */
  brandName: z.string().nullable(),
  /**
   * A standardised, formatted name for the medication, including active ingredient, strenght, and
   * formulation. Equivalent (typically) to the 'Medicinal Product Pack Name' defined by the
   * Australian Medicines Terminology (AMT). E.g. "bimatoprost 0.03% eye drops, 3 mL".
   *
   * Equivalent to 'schedule_form' in PBS details.
   *
   * See: https://data.pbs.gov.au/download/88776?filename=AMT-Model-Review-Concept-Definitions.pdf
   */
  medicinalProductPackName: z.string(),
  /**
   * PBS-specific information (null for non-PBS).
   */
  pbsDetails: annotatedPbsItemSchema.nullable(),
  /**
   * Whether the medication is on the LEMI (List of Excluded Medicinal Items).
   */
  isOnLemi: z.boolean(),
  /**
   * Whether the medication is on the LMBC (List of Medications for Brand Consideration).
   */
  isOnLmbc: z.boolean(),
  /**
   * Whether the medication requires compounding (e.g., preparation by a compounding pharmacy).
   */
  isCompoundingRequired: z.boolean(),
});

/**
 * A medication entity (PBS or non-PBS).
 */
export type Medication = z.infer<typeof medicationSchema>;
