import { z } from 'zod';

/**
 * A note on all PBS API schemas:
 * - We define only those fields that we are interested in. Do not rely on these schemas as a
 *   comprehensive representation of the PBS API response. Refer to the PBS API documentation for
 *   complete shapes (see https://data-api-portal.health.gov.au/apis)
 * - API release notes should be consulted if unexpected failures occur. The API may have changed
 *   since these schemas were last updated. See https://data.pbs.gov.au/api/api-release-notes.html
 * - The PBS API documentation defines every field as nullable for seemingly every schema. The
 *   overhead required to handle this is absurd. We will define non-nullable schemas for any field
 *   that we expect to always be present, that way it just fails validation early.
 */

export const pbsItemSchema = z
  .object({
    schedule_code: z.number().nullable(),
    li_item_id: z.string().nullable(),
    drug_name: z.string().nullable(),
    li_drug_name: z.string().nullable(),
    li_form: z.string().nullable(),
    schedule_form: z.string().nullable(),
    brand_name: z.string().nullable(),
    program_code: z.string().nullable(),
    pbs_code: z.string().nullable(),
    benefit_type_code: z.string().nullable(),
    caution_indicator: z.string().nullable(),
    note_indicator: z.string().nullable(),
    manner_of_administration: z.string().nullable(),
    maximum_prescribable_pack: z.number().nullable(),
    maximum_quantity_units: z.number().nullable(),
    number_of_repeats: z.number().nullable(),
    organisation_id: z.number().nullable(),
    manufacturer_code: z.string().nullable(),
    pack_size: z.number().nullable(),
    pricing_quantity: z.number().nullable(),
    pack_not_to_be_broken_ind: z.string().nullable(),
    claimed_price: z.number().nullable(),
    determined_price: z.number().nullable(),
    determined_qty: z.string().nullable(),
    safety_net_resupply_rule_days: z.number().nullable(),
    safety_net_resup_rule_cnt_ind: z.string().nullable(),
    extemporaneous_indicator: z.string().nullable(),
    extemporaneous_standard: z.string().nullable(),
    doctors_bag_group_id: z.number().nullable(),
    section100_only_indicator: z.string().nullable(),
    doctors_bag_only_indicator: z.string().nullable(),
    brand_substitution_group_id: z.number().nullable(),
    brand_substitution_group_code: z.string().nullable(),
    supply_only_indicator: z.string().nullable(),
    supply_only_date: z.string().nullable(),
    non_effective_date: z.string().nullable(),
    weighted_avg_disclosed_price: z.number().nullable(),
    originator_brand_indicator: z.string().nullable(),
    paper_med_chart_eligible_ind: z.string().nullable(),
    elect_med_chart_eligible_ind: z.string().nullable(),
    hsptl_med_chart_eligible_ind: z.string().nullable(),
    paper_med_chart_duration: z.number().nullable(),
    elect_med_chart_duration: z.number().nullable(),
    hsptl_chart_acute_duration: z.number().nullable(),
    hsptl_chart_sub_acute_duration: z.number().nullable(),
    hsptl_chart_chronic_duration: z.number().nullable(),
    pack_content: z.number().nullable(),
    vial_content: z.number().nullable(),
    infusible_indicator: z.string().nullable(),
    unit_of_measure: z.string().nullable(),
    continued_dispensing_flag: z.string().nullable(),
    continued_dispensing_emergency: z.string().nullable(),
    maximum_amount: z.number().nullable(),
    formulary: z.string().nullable(),
    water_added_ind: z.string().nullable(),
    section_19a_expiry_date: z.string().nullable(),
    container_fee_type: z.string().nullable(),
    policy_applied_imdq60_base_flag: z.string().nullable(),
    policy_applied_imdq60_flag: z.string().nullable(),
    policy_applied_bio_sim_up_flag: z.string().nullable(),
    policy_applied_indig_phar_flag: z.string().nullable(),
    therapeutic_exemption_indicator: z.string().nullable(),
    premium_exemption_group_id: z.number().nullable(),
    doctors_bag_group_title: z.string().nullable(),
    therapeutic_group_id: z.number().nullable(),
    therapeutic_group_title: z.string().nullable(),
    advanced_notice_date: z.string().nullable(),
    supply_only_end_date: z.string().nullable(),
    first_listed_date: z.string().nullable(),
    legal_unar_ind: z.string().nullable(),
    legal_car_ind: z.string().nullable(),
  })
  .passthrough();

export const AUTHORITY_METHOD_OPTIONS = [
  'STREAMLINED',
  'AUTHORITY_REQUIRED',
  'RESTRICTED',
] as const;
export type AuthorityMethod = (typeof AUTHORITY_METHOD_OPTIONS)[number];

export const pbsRestrictionSchema = z
  .object({
    schedule_code: z.number(),
    res_code: z.string(),
    treatment_phase: z.string().nullable(),
    /**
     * Authority is only actually required if the authority method is 'AUTHORITY_REQUIRED' or
     * 'STREAMLINED'.
     */
    authority_method: z.enum(AUTHORITY_METHOD_OPTIONS).nullable(),
    /**
     * This field is equivalent to the streamline authority code where the authority method is
     * 'STREAMLINED'. For any other authority method, this field should be largely ignored.
     */
    treatment_of_code: z.number().nullable(),
    restriction_number: z.number().nullable(),
    li_html_text: z.string().nullable(),
    /**
     * This is the HTML version of 'indications', or 'Restricted benefit' in the PBS website.
     */
    schedule_html_text: z.string().nullable(),
    note_indicator: z.string().nullable(),
    caution_indicator: z.string().nullable(),
    complex_authority_rqrd_ind: z.string().nullable(),
    assessment_type_code: z.string().nullable(),
    criteria_relationship: z.string().nullable(),
    variation_rule_applied: z.string().nullable(),
  })
  .passthrough();

/**
 * Restrictions are also broadly described as prescribing texts. The term prescribing texts
 * encompasses informational text such as notes and cautions.
 */
export const prescribingTextSchema = z
  .object({
    schedule_code: z.number(),
    prescribing_txt_id: z.number(),
    prescribing_type: z.string().nullable(),
    prescribing_txt: z.string().nullable(),
    prscrbg_txt_html: z.string().nullable(),
    complex_authority_rqrd_ind: z.string().nullable(),
    assessment_type_code: z.string().nullable(),
    apply_to_increase_mq_flag: z.string().nullable(),
    apply_to_increase_nr_flag: z.string().nullable(),
  })
  .passthrough();

export const annotatedPbsItemSchema = pbsItemSchema.merge(
  z.object({
    restrictions: z.array(pbsRestrictionSchema).optional(),
    prescribingTexts: z.array(prescribingTextSchema).optional(),
  }),
);

export type AnnotatedPbsItem = z.infer<typeof annotatedPbsItemSchema>;
