import { where } from 'firebase/firestore';
import { useCallback } from 'react';
import { practiceDocumentWithIdSchema } from '../types/firestore';
import { useCollection } from './useCollection';
import { PRACTICES_COLLECTION } from '../entities/collections';

/**
 * Custom hook to fetch practices for a given user.
 *
 * @param userId The ID of the user to fetch practices for.
 * @returns An object containing the practices, loading state, and any error.
 */
export function usePractices(userId: string) {
  const {
    data: practices,
    isLoading: isLoadingPractices,
    error: practicesError,
  } = useCollection(PRACTICES_COLLECTION, practiceDocumentWithIdSchema, {
    includeDocumentIds: true,
    getQueryConstraints: useCallback(() => [where('userId', '==', userId)], [userId]),
  });

  return { practices, isLoadingPractices, practicesError };
}
