import { StyledIndications } from './Restrictions.styled';
import { Accordion } from '@mantine/core';
import classes from './Restrictions.module.css';
import { Medication } from '../../types/medication';
import { checkIsPbsMedication } from '../RxForm/RxForm';

type RestrictionsProps = {
  medication: Medication | null;
  isInitiallyExpanded: boolean;
  isPbsPrescription: boolean;
};

type RestrictionProps = {
  rawRestrictionsHtml: string | null;
  isInitiallyExpanded: boolean;
};

/**
 * Processes the PBS 'Restriction text' HTML string to extract the relevant clinical criteria.
 */
function processRestrictionHtml(html: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  // The heading is identical for all medications and not part of the relevant restrinction
  const h1 = doc.querySelector('h1');
  if (h1) {
    h1.remove();
  }

  const paragraphsBeforeBreak: string[] = [];
  const paragraphsAfterBreak: string[] = [];

  let isAfterBreak = false;
  doc.body.childNodes.forEach((node) => {
    if (node.nodeName === 'BR') {
      isAfterBreak = true;
      return;
    }

    if (node.nodeName === 'P') {
      const text = node.textContent?.trim();
      if (text) {
        if (!isAfterBreak) {
          paragraphsBeforeBreak.push(text);
        } else {
          processPostBreakParagraph(text, paragraphsAfterBreak);
        }
      }
    }
  });

  return {
    paragraphsBeforeBreak,
    paragraphsAfterBreak,
  };
}

/**
 * Processes a paragraph of text that comes after the break in the restriction HTML.
 * Mainly concerned with paragraph separation according to logical separators to mimic
 * the PBS web UI.
 */
function processPostBreakParagraph(text: string, resultArray: string[]) {
  // NOTE: There's some consideration for 'OR' as a separator, but PBS doesn't seem to use it in
  // their web UI, so we're avoiding it here. Definitely no lower case separators.
  const separators = ['; AND'];
  let remainingText = text;

  separators.forEach((sep) => {
    const index = remainingText.indexOf(sep);
    if (index !== -1) {
      // Add text before separator
      const beforeText = remainingText.substring(0, index).trim();
      if (beforeText) {
        resultArray.push(beforeText);
      }

      // Add separator in uppercase
      resultArray.push(sep.replace(/; /, '').toUpperCase());

      // Update remaining text
      remainingText = remainingText.substring(index + sep.length).trim();
    }
  });

  // Add any remaining text
  if (remainingText) {
    resultArray.push(remainingText);
  }
}

/**
 * Generates the HTML content for the 'Indications' section of the medication details.
 * NOTE: This should really be renamed to 'Restrictions' as it's more accurate.
 *
 * @param rawRestrictionHtml The raw HTML string from the PBS API.
 * @returns The formatted HTML content for the 'Indications' section.
 */
function generateIndicationsHtml(rawRestrictionHtml: string) {
  if (rawRestrictionHtml === '') {
    return '';
  }

  const { paragraphsBeforeBreak, paragraphsAfterBreak } =
    processRestrictionHtml(rawRestrictionHtml);

  if (paragraphsBeforeBreak.length === 0 && paragraphsAfterBreak.length === 0) {
    return '';
  }

  // Generate the formatted HTML, starting with the lone indication
  let html = `<div class="Indication">`;

  // Don't separate by dot points or anything, just list the paragraphs
  paragraphsBeforeBreak.forEach((indication) => {
    html += `<div class="Indication__main">${indication}</div>`;
  });

  if (paragraphsAfterBreak.length == 0) {
    html += `</div>`;
    return html;
  }

  // We have additional criteria and they need special formatting
  html += `<div class="Indication__extra">
          <div class="Indication__clinical">Clinical criteria:</div>
          <ul class="Indication__list">`;

  paragraphsAfterBreak.forEach((point) => {
    if (point === 'AND' || point === 'OR') {
      // Render the logical separator  as its own element
      html += `<div class="Indication__and"><strong>${point}</strong></div>`;
    } else {
      // Render a regular bullet point for other criteria
      html += `<li class="Indication__list-item">${point}</li>`;
    }
  });

  // Whether or not there were additional criteria, close the main div
  html += `</div>`;

  return html;
}

/**
 * Formatted restriction content for a single restriction.
 */
const Restriction = ({ rawRestrictionsHtml, isInitiallyExpanded }: RestrictionProps) => {
  if (!rawRestrictionsHtml) {
    return null;
  }
  const indicationsHtml = generateIndicationsHtml(rawRestrictionsHtml);
  return (
    <StyledIndications className="Indications">
      <Accordion
        classNames={classes}
        defaultValue={isInitiallyExpanded ? 'Restricted benefit' : null}
      >
        <Accordion.Item value="Restricted benefit">
          <Accordion.Control>Restricted benefit</Accordion.Control>
          <Accordion.Panel>
            <div
              className="Indications__content"
              dangerouslySetInnerHTML={{ __html: indicationsHtml }}
            ></div>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </StyledIndications>
  );
};

/**
 * A special case for Ciclosporin, which has complex restrictions.
 */
function CiclosporinRestrictions() {
  return (
    <StyledIndications className="Indications">
      <Accordion classNames={classes} defaultValue="Restricted benefit">
        <Accordion.Item value="Restricted benefit">
          <Accordion.Control>Restricted benefit</Accordion.Control>
          <Accordion.Panel>
            <div className="Indications__content">
              <div className="Indication">
                <div className="Indication__main">
                  This medication has complex restrictions, please review the{' '}
                  <a
                    target="_blank"
                    href="https://www.pbs.gov.au/pbs/search?term=CICLOSPORIN&analyse=false&search-type=medicines"
                    rel="noreferrer"
                  >
                    PBS listings for Ciclosporin
                  </a>{' '}
                  for restriction details.
                </div>
              </div>
            </div>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </StyledIndications>
  );
}

export function Restrictions({
  medication,
  isInitiallyExpanded,
  isPbsPrescription,
}: RestrictionsProps) {
  if (!medication || !checkIsPbsMedication(medication) || !isPbsPrescription) {
    return null;
  }

  if (medication.activeIngredient.toLowerCase() === 'ciclosporin') {
    return <CiclosporinRestrictions />;
  }

  const restrictions = medication.pbsDetails.restrictions;
  if (!restrictions || restrictions.length === 0) {
    return null;
  }

  return restrictions.map((restriction) => {
    return (
      <Restriction
        key={restriction.res_code}
        rawRestrictionsHtml={restriction.li_html_text}
        isInitiallyExpanded={isInitiallyExpanded}
      />
    );
  });
}
