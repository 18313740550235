import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import { Helmet } from 'react-helmet-async';
import Subheading from '../utils/Subheading/Subheading';
import classes from './Admin.module.css';
import { ErrorHandlingSection } from './ErrorHandlingSection';

export const Admin = () => {
  return (
    <>
      <Helmet>
        <title>Admin · OptomRx</title>
        <meta name="admin" content="Handle administrator-only operations." />
        <link rel="canonical" href="/settings" />
      </Helmet>
      <ContentContainer>
        <PageHeader title="Admin" description="Handle administrator-only operations" />
        <section className={classes.section}>
          <Subheading text="PBS medication management" />
          <p>Coming soon.</p>
        </section>
        <section className={classes.section}>
          <Subheading text="Non-PBS medication management" />
          <p>Coming soon.</p>
        </section>
        <ErrorHandlingSection />
      </ContentContainer>
    </>
  );
};
