import { useState } from 'react';
import { useErrorHandling } from '../../hooks/useErrorHandling';
import { updatePassword } from 'firebase/auth';
import { Link } from 'react-router-dom';
import type { User } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import toast from 'react-hot-toast';
import { Alert, Button, PasswordInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { validateRequiredInput } from '../../utils/formUtils';
import { IconInfoCircle } from '@tabler/icons-react';
import classes from '../../styles/Alert.module.css';
import settingsClasses from './Settings.module.css';
import { logError } from '../../utils/logError';
import { refreshCredentials } from './Account';
import { logout } from '../../utils/logout';

type ChangePasswordProps = {
  user: User;
};

type FormValues = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const ChangePassword = ({ user }: ChangePasswordProps) => {
  const { handleSettingsError } = useErrorHandling();

  const [changePasswordPending, setChangePasswordPending] = useState(false);
  const [globalError, setGlobalError] = useState<string | null>(null);

  const form = useForm<FormValues>({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },

    validate: {
      currentPassword: (value) => validateRequiredInput(value, 'Please enter a password'),
      newPassword: (value) => validateRequiredInput(value, 'Please enter a password'),
      confirmPassword: (value, values) =>
        value !== values.newPassword ? 'Passwords do not match' : null,
    },
  });

  // Combine credential refresh with actual update password operation
  const performPasswordUpdate = async (currentPassword: string, newPassword: string) => {
    setChangePasswordPending(true);
    let reauthenticated = false;

    // Refresh the user's credentials regardless of recent sign in or not
    try {
      await refreshCredentials(user, currentPassword);
      reauthenticated = true;
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorMsg = handleSettingsError(error.code);
        setGlobalError(errorMsg);
      } else {
        logError(error);
        toast.error('Unable to change password. Please try again');
      }
      setChangePasswordPending(false);
    }
    // Proceed with operations once user reauthenticates
    if (reauthenticated) {
      try {
        await updatePassword(user, newPassword);
        toast.success('Password changed successfully');
        setChangePasswordPending(false);
      } catch (error) {
        setChangePasswordPending(false);
        if (error instanceof FirebaseError) {
          const errorMsg = handleSettingsError(error.code);
          setGlobalError(errorMsg);
        } else {
          logError(error);
          toast.error('Unable to change password. Please try again');
        }
      }
    }
  };

  return (
    <form
      className="password-form"
      noValidate
      onSubmit={form.onSubmit((values, event) => {
        event?.preventDefault();
        performPasswordUpdate(values.currentPassword, values.newPassword);
        if (!globalError) {
          form.reset();
        }
      })}
    >
      <div className="form-title">Change password</div>
      <PasswordInput
        label="Current password"
        autoComplete="password"
        {...form.getInputProps('currentPassword')}
        onChange={(event) => {
          form.getInputProps('currentPassword').onChange(event);
          setGlobalError('');
        }}
        classNames={{ root: settingsClasses.formInput }}
      />
      <PasswordInput
        label="New password"
        autoComplete="new-password"
        {...form.getInputProps('newPassword')}
        onChange={(event) => {
          form.getInputProps('newPassword').onChange(event);
          setGlobalError('');
        }}
        classNames={{ root: settingsClasses.formInput }}
      />
      <PasswordInput
        label="Confirm password"
        autoComplete="new-password"
        {...form.getInputProps('confirmPassword')}
        onChange={(event) => {
          form.getInputProps('confirmPassword').onChange(event);
          setGlobalError('');
        }}
        classNames={{ root: settingsClasses.formInput }}
      />

      {globalError ? (
        <Alert variant="light" icon={<IconInfoCircle />} color="red" className={classes.errorAlert}>
          {globalError}
        </Alert>
      ) : null}

      <div className="changePassword-btns">
        <Button
          type="submit"
          variant="primary"
          loading={changePasswordPending}
          classNames={{ root: settingsClasses.updatePasswordButton }}
        >
          Update password
        </Button>
        <Button
          component={Link}
          to="/reset-password"
          onClick={logout}
          variant="transparent"
          classNames={{
            root: settingsClasses.forgotPasswordButton,
          }}
        >
          Forgot password?
        </Button>
      </div>
    </form>
  );
};

export default ChangePassword;
