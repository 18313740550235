import { Helmet } from 'react-helmet-async';
import Subheading from '../utils/Subheading/Subheading';
import { styled } from 'styled-components';
import classes from './Settings.module.css';
import { TextInput, Button, Indicator, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import { updateDoc, doc } from 'firebase/firestore';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { db } from '../../firebase/config';
import { PrescriberData, UserDocumentWithId } from '../../types/firestore';
import { validateRequiredInput } from '../../utils/formUtils';
import { updateProfile, type User } from 'firebase/auth';
import { logError } from '../../utils/logError';
import { USERS_COLLECTION } from '../../entities/collections';

export const SettingsPage = styled.section`
  width: 100%;
`;

export const StyledFieldset = styled.fieldset`
  padding: 0;
  margin: 0;
  border: none;
`;

export const Profile = ({
  userDocument,
  user,
}: {
  userDocument: UserDocumentWithId;
  user: User;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { fullName, prescriberNumber, qualifications } = userDocument;

  const form = useForm<PrescriberData>({
    initialValues: {
      fullName,
      prescriberNumber,
      qualifications,
    },
    validate: {
      fullName: (value) => validateRequiredInput(value),
      prescriberNumber: (value) =>
        !/^[0-9]{7}$/.test(value.trim()) ? 'Prescriber number must be a seven digit number' : null,
    },
    validateInputOnBlur: true,
  });

  const handleSave = async () => {
    if (!form.isValid()) {
      toast.error('Please address the errors in the form');
      return;
    }
    setIsLoading(true);
    const { fullName, prescriberNumber, qualifications } = form.values;
    try {
      await updateDoc(doc(db, USERS_COLLECTION, userDocument.id), {
        fullName,
        prescriberNumber,
        qualifications,
      });
      // Sync firebase display name with full name in-app
      await updateProfile(user, {
        displayName: fullName,
      });
      toast.success('Details saved');
      form.resetDirty();
    } catch (error) {
      toast.error('An error occurred while saving your details');
      logError(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Helmet>
        <title>Profile · OptomRx</title>
        <meta name="profile" content="Update your profile and prescriber details." />
        <link rel="canonical" href="/settings/profile" />
      </Helmet>
      <SettingsPage>
        <Subheading text="Profile" />

        <form className="displayName-form">
          <StyledFieldset className={classes.personalDetailsFieldset}>
            <TextInput
              label="Full name"
              {...form.getInputProps('fullName')}
              classNames={{ root: classes.formInput }}
            />
            <TextInput
              label="Prescriber number"
              {...form.getInputProps('prescriberNumber')}
              classNames={{ root: classes.formInput }}
              maxLength={7}
            />
            <TextInput
              label="Abbreviated qualifications"
              {...form.getInputProps('qualifications')}
              classNames={{ root: classes.formInput }}
            />
          </StyledFieldset>
          <div className={classes.profileButtonContainer}>
            <Tooltip
              label={`You have unsaved changes. Click "Save" to update your profile.`}
              disabled={!form.isDirty()}
            >
              <Indicator
                color="orange"
                size={10}
                disabled={!form.isDirty()}
                className={classes.unsavedChangesIndicator}
              />
            </Tooltip>
            <Button
              variant="primary"
              onClick={handleSave}
              loading={isLoading}
              className={classes.profileSaveButton}
              disabled={isLoading || !form.isValid()}
            >
              Save
            </Button>
          </div>
        </form>
      </SettingsPage>
    </>
  );
};
