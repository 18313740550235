import { StyledRxTemplate, StyledFallback } from './RxTemplate.styled';
import tickbox from '../../assets/tickbox.svg';
import lightTick from '../../assets/light-tick.svg';
import largeTick from '../../assets/large-tick.svg';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { db } from '../../firebase/config';
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import PageHeader from '../utils/PageHeader/PageHeader';
import { useFormatting } from '../../hooks/useFormatting';
import { Helmet } from 'react-helmet-async';
import type { User } from 'firebase/auth';
import toast from 'react-hot-toast';
import { Button, Checkbox } from '@mantine/core';
import { IconDeviceFloppy, IconPrinter } from '@tabler/icons-react';
import { handleCheckboxEnterPress } from '../../utils/formUtils';
import { PrescriptionFormValues, Prescription } from '../../types/firestore';
import { logError } from '../../utils/logError';
import { Medication } from '../../types/medication';
import { checkIsAuthorityRequired } from '../RxForm/RxForm';
import { UseFormReturnType } from '@mantine/form';
import { PRESCRIPTIONS_COLLECTION } from '../../entities/collections';

export function checkIs60DayMedicine(medication: Medication) {
  if (!medication.pbsDetails) {
    return false;
  }
  return medication.pbsDetails.policy_applied_imdq60_flag === 'Y';
}

type RxTemplateProps = {
  user: User;
  form: UseFormReturnType<PrescriptionFormValues>;
};

const RxTemplate = ({ form, user }: RxTemplateProps) => {
  // Deconstructing data for cleanliness of code and easier-to-understand operations
  const { values: data, reset } = form;
  const { formatDrug, formatDate, formatPhoneNumber, formatMedicareNumber, formatPracticeAddress } =
    useFormatting();
  const navigate = useNavigate();

  const [isPending, setIsPending] = useState(false);
  const [includePrescriberCopy, setIncludePrescriberCopy] = useState(false);

  // Handle any input controlling boolean data, typically checkboxes
  const toggleIncludePrescriber = () => {
    setIncludePrescriberCopy((prevState) => !prevState);
  };

  // Default to include prescriber copies for all PBS scripts
  useEffect(() => {
    if (data?.drugData.pbsRx) {
      setIncludePrescriberCopy(true);
    }
  }, [data?.drugData.pbsRx]);

  // Fallback UI - used if directly navigating to this page without prescription data
  // It's not possible to have an empty active ingredient, so this is a reasonable check
  if (!data || data.drugData.activeIngredient === '') {
    return (
      <StyledFallback>
        No prescription data available. Please write a new prescription and try again.
      </StyledFallback>
    );
  }

  const { drugData, patientData, practiceData, miscData, prescriberData, medication } = data;

  // Used when the user clicks the 'save and finish' btn. Scripts will not be saved by default otherwise
  const saveRx = async () => {
    const newRx: Prescription = {
      activeIngredient: drugData.activeIngredient,
      authorityCode: miscData.authCode === '' ? null : miscData.authCode,
      authorityPrescriptionNumber: miscData.authRxNumber,
      brandName: drugData.brandName,
      clinicalJustification: miscData.justification,
      dosage: drugData.dosage,
      favouriteName: null,
      hasPreviouslyReceivedAuthority: miscData.prevAuth,
      isAuthoritySelected: drugData.authRequired,
      isBrandNameIncluded: drugData.includeBrand,
      isBrandNameOnly: drugData.brandOnly,
      isBrandSubstitutionPermitted: drugData.substitutePermitted,
      isCompoundingRequired: drugData.compounded,
      isFavourite: false,
      isPbsPrescription: drugData.pbsRx,
      // TODO: Implement this feature
      isRpbsPrescription: false,
      medicationId: drugData.medicationId,
      medicinalProductPackName: medication?.medicinalProductPackName ?? null,
      patientAge: miscData.age === '' ? null : miscData.age,
      pbsSnapshot:
        medication && medication.pbsDetails
          ? {
              isAuthorityRequired: checkIsAuthorityRequired(medication),
              maxQuantity: medication.pbsDetails.maximum_quantity_units,
              maxRepeats: medication.pbsDetails.number_of_repeats,
              pbsCode: medication.pbsDetails.pbs_code,
              restrictions: medication.pbsDetails.restrictions ?? null,
              // TODO: Create a util function that extracts this
              streamlineAuthorityCode: null,
              is60DayMedicine: checkIs60DayMedicine(medication),
              isOnLemi: medication.isOnLemi,
              isOnLmbc: medication.isOnLmbc,
            }
          : null,
      prescribedDate: Timestamp.now(),
      prescriber: {
        ...prescriberData,
        ...practiceData,
        uid: user.uid,
      },
      quantity: drugData.quantity,
      repeats: drugData.repeats,
      scriptId: miscData.scriptID,
      userDocumentId: user.uid,
    };
    setIsPending(true);
    try {
      // Save a script onto firebase referenced by script ID/Number, containing only non-identifiable information
      await setDoc(doc(db, PRESCRIPTIONS_COLLECTION, miscData.scriptID), newRx);
      setIsPending(false);
      toast.success('Prescription saved');
      reset();
      navigate('/');
    } catch (error) {
      setIsPending(false);
      toast.error('An error occurred while saving the script. Please try again.');
      logError(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Review prescription · OptomRx</title>
        <meta
          name="description"
          content="Review your prescription before printing. Make changes as needed, and save once complete."
        />
        <link rel="canonical" href="/prescription/review" />
      </Helmet>
      <StyledRxTemplate className="RxTemplate">
        <PageHeader title="Review your prescription" />
        <>
          <div className="ui-description">
            <div className="ui-info ui-date">{formatDate(miscData.date)}</div>
            <div className="ui-info ui-scriptNo">Script No: {miscData.scriptID}</div>
          </div>
          <div data-testid="ui" className="ui-container">
            <section className="ui-prescriber">
              <h4 className="ui__title">Prescriber</h4>

              <div className="ui-info ui-prescriber__contact-upper">
                <div className=" ui-prescriber__fullName">{`${practiceData.prefix ? 'Dr' : ''} ${
                  prescriberData.fullName
                }`}</div>
                {formatPracticeAddress(practiceData)}
                <div className=" ui-prescriber__addressLine2">
                  {`${practiceData.suburb} ${practiceData.state} ${practiceData.postcode}`}
                </div>
                <div className="ui-prescriber__phoneNumber">
                  {`Phone: ${formatPhoneNumber(practiceData.phoneNumber)}`}
                </div>
              </div>
              <div className="ui-info ui-prescriber__prescriberNumber">
                Prescriber number: {prescriberData.prescriberNumber}
              </div>
            </section>

            <section className="ui-patient">
              <h4 className="ui__title">Patient</h4>
              <div className="ui-info ui-patient__contactDetails">
                <div className="ui-patient__fullName">{patientData.fullName}</div>
                <div className="ui-patient__streetAddress">{`${patientData.subpremise} ${patientData.streetAddress}`}</div>
                <div className="ui-patient__addressLine2">{`${patientData.suburb} ${patientData.state} ${patientData.postcode}`}</div>
              </div>

              {patientData.medicareNumber.trim() !== '' && patientData.medicareRefNumber !== '' && (
                <div className="ui-info ui-medicare">
                  <div className="ui-patient__medicareNumber">
                    Medicare number:{' '}
                    {formatMedicareNumber(
                      patientData.medicareNumber,
                      patientData.medicareRefNumber,
                    )}
                  </div>
                </div>
              )}
            </section>

            <section className="ui-medication">
              <h4 className="ui__title">Medication</h4>
              <div className="ui-info ui-medication__primary-info">
                {formatDrug(drugData)}
                <div className="ui-medication__dosage">Dosage: {drugData.dosage}</div>
                <div className="ui-medication__quantity">{`Quantity: ${drugData.quantity}`}</div>
                <div className="ui-medication__repeats">{`Repeats: ${drugData.repeats}`}</div>
              </div>

              <div className="ui-info med-parameters">
                {drugData.substitutePermitted ? (
                  <div className="ui-brandSub--yes">Brand substitution permitted</div>
                ) : (
                  <div className="ui-brandSub--no">Brand substitution not permitted</div>
                )}
                {drugData.compounded && <div className="ui-compounded">To be compounded</div>}
              </div>
            </section>

            <section className="ui-miscellaneous">
              <h4 className="ui__title">PBS and Other</h4>
              {drugData.pbsRx ? (
                <div className="ui-info ui-pbsRx ui-pbsRx--selected">PBS prescription</div>
              ) : (
                <div className="ui-info ui-pbsRx ui-pbsRx">Private (non-PBS) prescription</div>
              )}
              {drugData.authRequired && (
                <div className="ui-auth ui-info">
                  <div className="ui-authCode">{`Authority Approval No: ${miscData.authCode}`}</div>
                  <div className="ui-authRxNo">{`Authority Script No: ${miscData.authRxNumber}`}</div>
                  <div className="ui-justification">
                    <div className="ui-prevAuth">{`Patient has previously received authority: ${
                      miscData.prevAuth ? 'Yes' : 'No'
                    }`}</div>
                    <div className="ui-age">{miscData.age && `Patient's age: ${miscData.age}`}</div>
                    <div className="ui-justification">{`Clinical justification for use of item: ${
                      miscData.justification === '' ? 'None provided' : miscData.justification
                    }`}</div>
                  </div>
                </div>
              )}
              <div className="include-prescriber ui-info">
                <Checkbox
                  label="Include prescriber copy"
                  onChange={toggleIncludePrescriber}
                  //! Prescriber copy MUST be included on authority PBS scripts
                  checked={includePrescriberCopy || drugData.authRequired}
                  className="checkbox"
                  onKeyDown={handleCheckboxEnterPress}
                />
              </div>
            </section>
          </div>

          <div className="upper-containers">
            <div className="left-container">
              <section className="prescriber-upper">
                <h4 className="prescriber__title">Prescriber</h4>
                <div className="container">
                  <div className="prescriber__contact-upper">
                    <div className="prescriber__fullName">{`${practiceData.prefix ? 'Dr' : ''} ${
                      prescriberData.fullName
                    }`}</div>
                    {formatPracticeAddress(practiceData)}
                    <div className="prescriber__addressLine2">
                      {`${practiceData.suburb} ${practiceData.state} ${practiceData.postcode}`}
                    </div>
                  </div>
                  <div className="prescriber__contact-lower">
                    <div className="prescriber__prescriberNumber">
                      {prescriberData.prescriberNumber}
                    </div>
                    <div data-testid="phone" className="prescriber__phoneNumber">
                      {`Phone: ${formatPhoneNumber(practiceData.phoneNumber)}`}
                    </div>
                  </div>
                </div>
              </section>

              <section className="patient">
                <h4 className="patient__title">Patient</h4>
                <div className="container">
                  <div className="patient__medicareNumber">
                    {`${patientData.medicareNumber.substring(
                      0,
                      4,
                    )} ${patientData.medicareNumber.substring(
                      4,
                      9,
                    )} ${patientData.medicareNumber.substring(9, 10)}-${
                      patientData.medicareRefNumber
                    }`}
                  </div>

                  {patientData.medicareNumber.trim() !== '' &&
                    patientData.medicareRefNumber !== '' && (
                      <div className="patient__medicareNumber">
                        {formatMedicareNumber(
                          patientData.medicareNumber,
                          patientData.medicareRefNumber,
                        )}
                      </div>
                    )}

                  <div className="patient__contactDetails">
                    <div className="patient__fullName">{patientData.fullName}</div>
                    <div className="patient__streetAddress">{`${patientData.subpremise} ${patientData.streetAddress}`}</div>
                    <div className="patient__addressLine2">{`${patientData.suburb} ${patientData.state} ${patientData.postcode}`}</div>
                  </div>
                </div>
              </section>

              <section className="miscellaneous">
                {/* Include Script ID and Authority Rx number here */}
                <div className="date">{formatDate(miscData.date)}</div>
                {drugData.pbsRx ? (
                  <div className="pbsSelected">
                    <img className="pbsTick" src={tickbox} alt="" />
                  </div>
                ) : (
                  <div className="nonPbs">
                    <span className="nonPbs-marker">XXXXXXXXXXX</span>Non-PBS
                  </div>
                )}
                {!drugData.substitutePermitted && (
                  <div className="brandSub">
                    <img className="brandSubTick" src={largeTick} alt="" />
                  </div>
                )}
                <div className="scriptNo">Script No: {miscData.scriptID}</div>
              </section>

              {/* Script ID or authority Rx number should go above the medication once finalised, and perhaps with a border bottom */}
              <section className="medication">
                <h4 className="medication__title">Medication</h4>
                <div data-testid="drugName" className="medication__activeIngredient">
                  {formatDrug(drugData)}
                </div>
                {drugData.compounded && (
                  <div className="medication__compounded">To be compounded</div>
                )}
                <div className="medication__dosage">{drugData.dosage}</div>
                <div className="quantityRepeats">
                  <div className="medication__quantity">{`Quantity: ${drugData.quantity}`}</div>
                  <div className="medication__repeats">{`${drugData.repeats} repeats`}</div>
                </div>
                <span className="item-printed">1 item printed</span>
              </section>
              {/* Will only ever be 1 item printed, so consider omitting this */}

              <section className="prescriber-lower">
                {/* Used to display prescriber details next to, or below signature space */}
                <div
                  className={`${
                    prescriberData.qualifications === ''
                      ? 'prescriber__fullName prescriber__fullName--low'
                      : 'prescriber__fullName'
                  }`}
                >{`${practiceData.prefix ? 'Dr' : ''} ${prescriberData.fullName}`}</div>
                {/* Qualifications should only be included in the lower section */}
                <div className="prescriber__qualifications">{prescriberData.qualifications}</div>
                <div className="practitionerTick">
                  <img className="optomTick" src={lightTick} alt="" />
                </div>
              </section>

              {/* Wastes space to render authority section for non-authority required scripts, so render only as needed */}
              {drugData.authRequired && (
                <section className="authority">
                  <div className="authority__approvalCode">{`Authority Approval No: ${miscData.authCode}`}</div>
                  <div className="authRxNo">{`Authority Script No: ${miscData.authRxNumber}`}</div>
                  {/* Optional sections below - not sure how useful these are in this day and age */}
                  {/* <div className="authority__authorised">Authorised</div> */}
                  {/*<div className="authority__delegate">Delegate...............</div> */}
                </section>
              )}
            </div>

            <div className="right-container">
              <section className="prescriber-upper">
                <div className="container">
                  <div className="prescriber__contact-upper">
                    <div className="prescriber__fullName">{`${practiceData.prefix ? 'Dr' : ''} ${
                      prescriberData.fullName
                    }`}</div>
                    {formatPracticeAddress(practiceData)}
                    <div className="prescriber__addressLine2">
                      {`${practiceData.suburb} ${practiceData.state} ${practiceData.postcode}`}
                    </div>
                  </div>
                  <div className="prescriber__contact-lower">
                    <div className="prescriber__prescriberNumber">
                      {prescriberData.prescriberNumber}
                    </div>
                    <div className="prescriber__phoneNumber">
                      {`Phone: ${formatPhoneNumber(practiceData.phoneNumber)}`}
                    </div>
                  </div>
                </div>
              </section>

              <section className="patient">
                <div className="container">
                  {patientData.medicareNumber.trim() !== '' &&
                    patientData.medicareRefNumber !== '' && (
                      <div className="patient__medicareNumber">
                        {formatMedicareNumber(
                          patientData.medicareNumber,
                          patientData.medicareRefNumber,
                        )}
                      </div>
                    )}

                  <div className="patient__contactDetails">
                    <div className="patient__fullName">{patientData.fullName}</div>
                    <div className="patient__streetAddress">{`${patientData.subpremise} ${patientData.streetAddress}`}</div>
                    <div className="patient__addressLine2">{`${patientData.suburb} ${patientData.state} ${patientData.postcode}`}</div>
                  </div>
                </div>
              </section>

              <section className="miscellaneous">
                {/* Include Script ID and Authority Rx number here */}
                <div className="date">{formatDate(miscData.date)}</div>
                {drugData.pbsRx ? (
                  <div className="pbsSelected">
                    <img className="pbsTick" src={tickbox} alt="" />
                  </div>
                ) : (
                  <div className="nonPbs">
                    <span className="nonPbs-marker">XXXXXXXXXXX</span>Non-PBS
                  </div>
                )}
                {!drugData.substitutePermitted && (
                  <div className="brandSub">
                    <img className="brandSubTick" src={largeTick} alt="" />
                  </div>
                )}
                <div className="scriptNo">Script No: {miscData.scriptID}</div>
              </section>

              {/* Script ID or authority Rx number should go above the medication once finalised, and perhaps with a border bottom */}
              <section className="medication">
                <div className="medication__activeIngredient">{formatDrug(drugData)}</div>
                {drugData.compounded && (
                  <div className="medication__compounded">To be compounded</div>
                )}
                <div className="medication__dosage">{drugData.dosage}</div>
                <div className="quantityRepeats">
                  <div className="medication__quantity">{`Quantity: ${drugData.quantity}`}</div>
                  <div className="medication__repeats">{`${drugData.repeats} repeats`}</div>
                </div>
                <span className="item-printed">1 item printed</span>
              </section>
              {/* Will only ever be 1 item printed, so consider omitting this */}

              <section className="prescriber-lower">
                {/* Used to display prescriber details next to, or below signature space */}
                <div
                  className={`${
                    prescriberData.qualifications === ''
                      ? 'prescriber__fullName prescriber__fullName--low'
                      : 'prescriber__fullName'
                  }`}
                >{`${practiceData.prefix ? 'Dr' : ''} ${prescriberData.fullName}`}</div>
                {/* Qualifications should only be included in the lower section */}
                <div className="prescriber__qualifications">{prescriberData.qualifications}</div>
              </section>

              {/* Wastes space to render authority section for non-authority required scripts, so render only as needed */}
              {drugData.authRequired && (
                <section className="authority">
                  <div className="authority__approvalCode">{`Authority Approval No: ${miscData.authCode}`}</div>
                  <div className="authRxNo">{`Authority Script No: ${miscData.authRxNumber}`}</div>
                </section>
              )}
            </div>
          </div>

          <div className="lower-containers">
            {includePrescriberCopy && (
              <div className="bottom-container--left">
                <span className="doctor-copy">--Prescriber&apos;s Copy--</span>
                <section className="prescriber-upper">
                  <div className="container">
                    <div className="prescriber__contact-upper">
                      <div className="prescriber__fullName">{`${practiceData.prefix ? 'Dr' : ''} ${
                        prescriberData.fullName
                      }`}</div>
                      {/* Consider appending qualifications after prescriber name in this copy? */}
                      <div className="prescriber__addressLine2">
                        {formatPracticeAddress(practiceData)}
                        {`${practiceData.suburb} ${practiceData.state} ${practiceData.postcode}`}
                      </div>
                    </div>
                    <div className="prescriber__contact-lower">
                      <div className="prescriber__phoneNumber">
                        {`Phone: ${formatPhoneNumber(practiceData.phoneNumber)}`}
                      </div>
                      <div className="prescriber__prescriberNumber">
                        Prescriber No. {prescriberData.prescriberNumber}
                      </div>
                    </div>
                  </div>
                </section>

                <section className="patient">
                  <div className="container">
                    <span className="patient__label">Patient:</span>
                    <div className="patient__contactDetails">
                      <div className="patient__fullName">{patientData.fullName}</div>
                      <div className="patient__streetAddress">{`${patientData.subpremise} ${patientData.streetAddress}`}</div>
                      <div className="patient__addressLine2">{`${patientData.suburb} ${patientData.state} ${patientData.postcode}`}</div>
                      {patientData.medicareNumber.trim() !== '' &&
                        patientData.medicareRefNumber !== '' && (
                          <div className="patient__medicareNumber">
                            Medicare No:{' '}
                            {formatMedicareNumber(
                              patientData.medicareNumber,
                              patientData.medicareRefNumber,
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                </section>

                <section className="miscellaneous">
                  <div className="date">{formatDate(miscData.date)}</div>
                  {drugData.authRequired && (
                    <div className="authNumbers">
                      <div className="authRxNo">{`Authority Script No: ${miscData.authRxNumber}`}</div>
                    </div>
                  )}
                </section>

                <section className="medication">
                  <div className="medication__activeIngredient">{formatDrug(drugData)}</div>
                  {drugData.compounded && (
                    <div className="medication__compounded">To be compounded</div>
                  )}
                  <div className="medication__dosage">{drugData.dosage}</div>
                  <div className="quantityRepeats">
                    <div className="medication__quantity">{`Quantity: ${drugData.quantity}`}</div>
                    <div className="medication__repeats">{`${drugData.repeats} repeats`}</div>
                  </div>
                  <div className="item-printed-line">
                    {drugData.authRequired && (
                      <div className="authority__approvalCode">{`Authority Approval No: ${miscData.authCode}`}</div>
                    )}
                    <span className="item-printed">1 item printed</span>
                  </div>

                  {drugData.authRequired && (
                    <>
                      <section className="authority">
                        <div className="extra-details">
                          <div className="prev-auth">{`Patient has received authority for this medicine before: ${
                            miscData.prevAuth ? 'Yes' : 'No'
                          }`}</div>
                          {miscData.age !== '' && (
                            <div className="patient-age">Patient&apos;s age: {miscData.age}</div>
                          )}
                        </div>
                      </section>
                      <div className="indication">
                        Clinical justification for use of item: {miscData.justification}
                      </div>
                    </>
                  )}
                </section>
              </div>
            )}

            {drugData.authRequired && (
              <div className="bottom-container--right">
                <span className="doctor-copy">--Services Australia/DVA Copy--</span>
                <section className="prescriber-upper">
                  <div className="container">
                    <div className="prescriber__contact-upper">
                      <div className="prescriber__fullName">{`${practiceData.prefix ? 'Dr' : ''} ${
                        prescriberData.fullName
                      }`}</div>
                      {/* Consider appending qualifications after prescriber name in this copy? */}
                      {formatPracticeAddress(practiceData)}
                      <div className="prescriber__addressLine2">
                        {`${practiceData.suburb} ${practiceData.state} ${practiceData.postcode}`}
                      </div>
                    </div>
                    <div className="prescriber__contact-lower">
                      <div className="prescriber__phoneNumber">
                        {`Phone: ${formatPhoneNumber(practiceData.phoneNumber)}`}
                      </div>
                      <div className="prescriber__prescriberNumber">
                        Prescriber No. {prescriberData.prescriberNumber}
                      </div>
                    </div>
                  </div>
                </section>

                <section className="patient">
                  <div className="container">
                    <span className="patient__label">Patient:</span>
                    <div className="patient__contactDetails">
                      <div className="patient__fullName">{patientData.fullName}</div>
                      <div className="patient__streetAddress">{`${patientData.subpremise} ${patientData.streetAddress}`}</div>
                      <div className="patient__addressLine2">{`${patientData.suburb} ${patientData.state} ${patientData.postcode}`}</div>

                      {patientData.medicareNumber.trim() !== '' &&
                        patientData.medicareRefNumber !== '' && (
                          <div className="patient__medicareNumber">
                            Medicare No:{' '}
                            {formatMedicareNumber(
                              patientData.medicareNumber,
                              patientData.medicareRefNumber,
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                </section>

                <section className="miscellaneous">
                  <div className="date">{formatDate(miscData.date)}</div>
                  {drugData.authRequired && (
                    <div className="authNumbers">
                      <div className="authRxNo">{`Authority Script No: ${miscData.authRxNumber}`}</div>
                    </div>
                  )}
                </section>

                <section className="medication">
                  <div className="medication__activeIngredient">{formatDrug(drugData)}</div>
                  {drugData.compounded && (
                    <div className="medication__compounded">To be compounded</div>
                  )}
                  <div className="medication__dosage">{drugData.dosage}</div>
                  <div className="quantityRepeats">
                    <div className="medication__quantity">{`Quantity: ${drugData.quantity}`}</div>
                    <div className="medication__repeats">{`${drugData.repeats} repeats`}</div>
                  </div>
                  <div className="item-printed-line">
                    {drugData.authRequired && (
                      <div className="authority__approvalCode">{`Authority Approval No: ${miscData.authCode}`}</div>
                    )}
                    <span className="item-printed">1 item printed</span>
                  </div>

                  {drugData.authRequired && (
                    <>
                      <section className="authority">
                        <div className="extra-details">
                          <div className="prev-auth">{`Patient has received authority for this medicine before: ${
                            miscData.prevAuth ? 'Yes' : 'No'
                          }`}</div>
                          {miscData.age !== '' && (
                            <div className="patient-age">Patient&apos;s age: {miscData.age}</div>
                          )}
                        </div>
                      </section>
                      <div className="indication">
                        Clinical justification for use of item: {miscData.justification}
                      </div>
                    </>
                  )}
                </section>
              </div>
            )}
          </div>

          <div className="RxTemplate__btns">
            <div className="primary-btns">
              <Button
                className="btn-print"
                onClick={() => {
                  window.print();
                }}
                variant="primary"
                leftSection={<IconPrinter width={20} />}
              >
                Print
              </Button>
              <Button
                onClick={saveRx}
                loading={isPending}
                variant="primary"
                leftSection={<IconDeviceFloppy width={20} />}
              >
                Save and Finish
              </Button>
            </div>

            <Button component={Link} variant="grey" to="/prescription/edit">
              Make changes
            </Button>
          </div>
        </>
      </StyledRxTemplate>
    </>
  );
};

export default RxTemplate;
