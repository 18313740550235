import { Link, useNavigate } from 'react-router-dom';
import { formatPrescribedMedication } from '../../hooks/useFormatting';
import usePagination from '../../hooks/usePagination';
import { Prescription } from '../../types/firestore';
import { Anchor, Pagination } from '@mantine/core';

type ScriptsTable = {
  scriptsPerPage: number;
  scripts: Prescription[];
};

const ScriptsTable = ({ scripts, scriptsPerPage }: ScriptsTable) => {
  const {
    currentItems: currentScripts,
    setItemsForCurrentPage,
    pageCount,
  } = usePagination(scripts, scriptsPerPage);

  const navigate = useNavigate();
  return (
    <>
      <table className="table data-table" aria-describedby="Scripts__description">
        <thead className="tableRowHeader">
          <tr>
            <th className="tableHeader" scope="col">
              Script ID
            </th>
            <th className="tableHeader" scope="col">
              Medication
            </th>
            <th className="tableHeader" scope="col">
              Date prescribed
            </th>
          </tr>
        </thead>
        <tbody>
          {/* Switch key back to key={script.scriptID} once testing is complete */}
          {currentScripts.map((script, index) => (
            <tr
              className="tableRowItems"
              key={index}
              onClick={() => navigate(`/scripts/${script.scriptId}`)}
            >
              <td data-title="Script ID" className="tableCell">
                <Anchor
                  component={Link}
                  to={`/scripts/${script.scriptId}`}
                  underline="always"
                  className="link"
                  onClick={(e) => e.stopPropagation()}
                >
                  {script.scriptId}
                </Anchor>
              </td>
              <td data-title="Medication" className="tableCell">
                {formatPrescribedMedication(script)}
              </td>
              <td data-title="Date prescribed" className="tableCell">
                {script.prescribedDate.toDate().toLocaleDateString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <Pagination total={pageCount} onChange={setItemsForCurrentPage} size="sm" />
      </div>
    </>
  );
};

export default ScriptsTable;
