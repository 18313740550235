import { useSearchParams } from 'react-router-dom';

/**
 * Hook to extract a boolean value from a URL query parameter.
 * Only accepts "true" or "false" (case-insensitive).
 *
 * @param paramName The name of the boolean query parameter.
 * @returns True if explicitly "true", False otherwise.
 */
export const useBooleanQueryParam = (paramName: string): boolean => {
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get(paramName)?.toLowerCase();

  if (paramValue === 'true') {
    return true;
  }

  if (paramValue === 'false') {
    return false;
  }

  if (paramValue != null) {
    console.error(
      `Invalid boolean value for "${paramName}": "${paramValue}". Expected "true" or "false".`,
    );
  }

  return false;
};
