import { Skeleton, SkeletonProps } from '@mantine/core';
import classes from './Skeletons.module.css';

export type CheckboxInputSkeletonProps = {
  /**
   * Width of the input skeleton. Optional. Default = 200.
   */
  inputWidth?: SkeletonProps['width'];
};

/**
 * A skeleton component that mimics the layout of a checkbox form input.
 */
export function CheckboxInputSkeleton({
  inputWidth = 200,
}: {
  inputWidth?: SkeletonProps['width'];
}) {
  return (
    <div className={classes.inputSkeletonContainer}>
      <Skeleton height={20} width={inputWidth} className={classes.inputSkeleton} />
    </div>
  );
}
