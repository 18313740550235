import { useState, useEffect, useRef } from 'react';
import { StyledRxForm } from './RxForm.styled';
import Fieldset from '../utils/Fieldset/Fieldset';
import { useNumbers } from '../../hooks/useNumbers';
import { Link, useNavigate } from 'react-router-dom';
import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import { PracticeDetails } from '../PracticeDetails/PracticeDetails';
import PatientDetails from '../PatientDetails/PatientDetails';
import MedicationDetails from '../MedicationDetails/MedicationDetails';
import AuthorityDetails from '../AuthorityDetails/AuthorityDetails';
import { Helmet } from 'react-helmet-async';
import { PrescriptionFormValues } from '../../types/firestore';
import { AlertState } from '../utils/Alert/Alert';
import type { User } from 'firebase/auth';
import { Anchor, Button } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useUserDocument } from '../../hooks/useUserDocument';
import { PrescriberDetails } from '../PrescriberDetails/PrescriberDetails';
import { Medication } from '../../types/medication';
import { usePrescriptionInitialValues } from '../../context/PrescriptionInitialValuesContext';
import { useBooleanQueryParam } from '../../hooks/useBooleanQueryParam';
import { useMedications } from '../../context/MedicationsContext';
import { MedicationDetailsSkeleton } from '../MedicationDetails/MedicationDetailsSkeleton';
import classes from './RxForm.module.css';
import { SupplyOnlyAlert } from './SupplyOnlyAlert';
import { useHandleLEMI } from '../../hooks/useHandleLEMI';

// Multiple items are not permitted to be prescribed on the same form; each must use an individual form (applies to optometrists only)
type RxFormProps = {
  googleLoaded: boolean;
  user: User;
  form: UseFormReturnType<PrescriptionFormValues>;
  /**
   * The mode in which the form is being used.
   */
  mode: 'new' | 'edit';
};

const UNRESTRICTED_BENEFIT_TYPE_CODE = 'U';
const RESTRICTED_BENEFIT_TYPE_CODE = 'R';
const AUTHORITY_BENEFIT_TYPE_CODE = 'A';
const STREAMLINED_AUTHORITY_BENEFIT_TYPE_CODE = 'S';

/**
 * Returns true if the medication is a PBS item.
 */
export function checkIsPbsMedication(
  medication: Medication,
): medication is Medication & { pbsDetails: NonNullable<Medication['pbsDetails']> } {
  return medication.pbsDetails !== null;
}

/**
 * Returns true if the medication requires authority.
 * Only applies to PBS medications.
 */
export function checkIsAuthorityRequired(medication: Medication): boolean {
  if (!checkIsPbsMedication(medication)) {
    return false;
  }
  const benefitTypeCode = medication.pbsDetails.benefit_type_code;
  return (
    benefitTypeCode === AUTHORITY_BENEFIT_TYPE_CODE ||
    benefitTypeCode === STREAMLINED_AUTHORITY_BENEFIT_TYPE_CODE
  );
}

/**
 * Returns true if the medication has 'supply only' status.
 */
export function checkIsSupplyOnly(medication: Medication): boolean {
  if (!checkIsPbsMedication(medication)) {
    return false;
  }
  return medication.pbsDetails.supply_only_indicator === 'Y';
}

/**
 * Extracts the streamline authority code from a medication.
 */
export function getStreamlineAuthorityCode(medication: Medication): number | null {
  if (!checkIsPbsMedication(medication)) {
    return null;
  }
  if (medication.pbsDetails.benefit_type_code !== STREAMLINED_AUTHORITY_BENEFIT_TYPE_CODE) {
    return null;
  }
  const restrictions = medication.pbsDetails.restrictions;
  if (!restrictions) {
    throw new Error(
      `Streamlined authority medication (ID: ${medication.id}) has no restrictions to extract code from`,
    );
  }
  const streamlineRestriction = restrictions.find(
    (restriction) => restriction.authority_method === 'STREAMLINED',
  );

  if (!streamlineRestriction) {
    throw new Error(
      `Streamlined authority medication (ID: ${medication.id}) has no STREAMLINED restriction`,
    );
  }
  const streamlineCode = streamlineRestriction.treatment_of_code;
  if (!streamlineCode) {
    throw new Error(
      `Streamlined authority medication (ID: ${medication.id}) has no treatment of code`,
    );
  }
  return streamlineCode;
}

/**
 * Returns true if the medication is a PBS item with restrictions.
 */
export function checkIsRestricted(medication: Medication): boolean {
  if (!checkIsPbsMedication(medication)) {
    return false;
  }
  return medication.pbsDetails.benefit_type_code === RESTRICTED_BENEFIT_TYPE_CODE;
}

/**
 * Returns true if the medication is a PBS item without restrictions.
 */
export function checkIsUnrestricted(medication: Medication): boolean {
  if (!checkIsPbsMedication(medication)) {
    return false;
  }
  return medication.pbsDetails.benefit_type_code === UNRESTRICTED_BENEFIT_TYPE_CODE;
}

/**
 * Gets the drug alerts for a medication after selecting whether the prescription is a PBS or
 * non-PBS prescription.
 */
export function getPostPbsSelectionDrugAlerts(medication: Medication): AlertState {
  // Non-PBS medications do not have any assosciated alerts.
  if (!checkIsPbsMedication(medication)) {
    return {};
  }

  const alerts: AlertState = {
    // Set base 'max quantity' and 'max repeats' alerts, which apply to all PBS medications
    maxQuantity: {
      message: `Maximum allowed quantity under the PBS is ${medication.pbsDetails.maximum_quantity_units}`,
      kind: 'neutral',
    },
    maxRepeats: {
      message: `Maximum allowed repeats under the PBS is ${medication.pbsDetails.number_of_repeats}`,
      kind: 'neutral',
    },
  };

  if (checkIsAuthorityRequired(medication)) {
    alerts.authRequired = {
      message: 'This item requires an authority prescription',
      kind: 'neutral',
    };
  }

  return alerts;
}

/**
 * Gets the 'miscellaneous' alerts for a medication after selecting whether the prescription is a
 * PBS or non-PBS prescription.
 */
export const getPostPbsSelectionMiscAlerts = ({
  medication,
  isPbsPrescription,
}: {
  medication: Medication;
  isPbsPrescription: boolean;
}): AlertState => {
  if (!checkIsPbsMedication(medication) || !isPbsPrescription) {
    return {};
  }

  if (!checkIsAuthorityRequired(medication)) {
    return {};
  }

  const alerts: AlertState = {};

  const streamlineAuthorityCode = getStreamlineAuthorityCode(medication);
  if (streamlineAuthorityCode) {
    alerts.authCode = {
      message: 'This medication is available using the streamline code above',
      kind: 'success',
    };
  } else {
    alerts.authCode = {
      message: (
        <span>
          This medication requires an authority code, which can be obtained through{' '}
          <Anchor
            href="https://proda.humanservices.gov.au/"
            target="_blank"
            rel="noreferrer"
            className="proda-link"
          >
            PRODA
          </Anchor>
        </span>
      ),
      kind: 'neutral',
    };
  }

  return alerts;
};

/**
 * Gets the initial alerts for a medication prior to selecting whether the prescription is a PBS
 * or non-PBS prescription.
 */
export const getPrePbsSelectionDrugAlerts = (medication: Medication): AlertState => {
  if (!checkIsPbsMedication(medication)) {
    return {
      maxQuantity: null,
      maxRepeats: null,
      pbsRx: {
        message: 'This item is not available on the PBS',
        kind: 'neutral',
      },
      authRequired: {
        message: 'This prescription does not require authority',
        kind: 'neutral',
      },
    };
  }

  const alerts: AlertState = {};

  // No prescription requires authority until the user selects that it is a PBS prescription,
  // at which point it then becomes dependent on the medication (see post-PBS selection alerts)
  alerts.authRequired = {
    message: 'This prescription does not require authority',
    kind: 'neutral',
  };

  if (checkIsRestricted(medication)) {
    alerts.pbsRx = {
      message: 'This item is available on the PBS (restrictions apply)',
      kind: 'neutral',
    };
  } else if (checkIsUnrestricted(medication)) {
    alerts.pbsRx = {
      message: 'This item is available on the PBS (unrestricted)',
      kind: 'neutral',
    };
  }

  if (checkIsAuthorityRequired(medication)) {
    alerts.pbsRx = {
      message: 'This item is available on the PBS (authority required)',
      kind: 'neutral',
    };
  }
  return alerts;
};

const defaultMedications: Medication[] = [];

const RxForm = ({ googleLoaded, user, form, mode }: RxFormProps) => {
  const { scriptNo, authRxNo, isLoadingNumbers, fetchNumbers } = useNumbers();
  const { medications, isLoadingMedications } = useMedications();
  const navigate = useNavigate();
  const { data: userDocument } = useUserDocument(user.uid);

  const { LEMIComponent, handleLEMIInfo, removeLEMIInfo } = useHandleLEMI(form);

  // Avoid potentially re-attempting number fetching/transactions on component re-renders
  const hasFetchedNumbers = useRef(false);

  const { setFieldValue, setValues } = form;

  const [drugAlerts, setDrugAlerts] = useState<AlertState>({
    name: null,
    pbsRx: {
      message: 'Select a medication from the dropdown list for PBS information',
      kind: 'neutral',
    },
    maxQuantity: null,
    maxRepeats: null,
    activeIngredient: null,
    authRequired: {
      message: 'This prescription does not require authority',
      kind: 'neutral',
    },
  });

  const [miscAlerts, setMiscAlerts] = useState<AlertState>({
    authCode: null,
  });

  const { initialValues, resetInitialValues } = usePrescriptionInitialValues();

  const isReprescribing = useBooleanQueryParam('isReprescribing');

  // On mount, initialise the form values with the context data
  useEffect(() => {
    // Separate out practice and prescriber data as these are set elsewhere and should not be
    // overwritten
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { practiceData, prescriberData, ...rest } = initialValues;
    if (mode === 'edit') {
      // Form state will already have the expected information. No need to set any new data.
      return;
    }

    if (mode === 'new') {
      if (!isReprescribing) {
        // Reset the form to the initial values. Avoids callers needing to handle this.
        resetInitialValues();
      }

      // Start by setting the form values to the initial values, which will be different depending
      // on whether the user is represcribing or not (this is handled on navigation to this page)
      setValues({
        ...rest,
        miscData: {
          ...initialValues.miscData,
          scriptID: scriptNo,
          authRxNumber: authRxNo,
        },
      });
      if (!hasFetchedNumbers.current) {
        // Use .then() to ensure the above scriptNo and authRxNo variables are set prior to attempting to set data state with them
        fetchNumbers().then(() => {
          setFieldValue('miscData.scriptID', scriptNo);
          setFieldValue('miscData.authRxNumber', authRxNo);
        });
        hasFetchedNumbers.current = true;
      }

      // In the case of represcribing, we need to check whether the user is represcribing a
      // medication in the database. If so, we need to set the medication field to that value,
      // using updated medication values.
      if (isReprescribing && initialValues.drugData.medicationId) {
        const medication = medications?.find(
          (med) => med.id === initialValues.drugData.medicationId,
        );
        if (medication) {
          setFieldValue('medication', medication);
          const drugAlerts = {
            ...getPrePbsSelectionDrugAlerts(medication),
            ...getPostPbsSelectionDrugAlerts(medication),
          };
          const miscAlerts = {
            ...getPostPbsSelectionMiscAlerts({
              medication,
              isPbsPrescription: initialValues.drugData.pbsRx,
            }),
          };
          setDrugAlerts((prevAlerts) => ({
            ...prevAlerts,
            ...drugAlerts,
          }));
          setMiscAlerts((prevAlerts) => ({
            ...prevAlerts,
            ...miscAlerts,
          }));
        }
        // ? Worth adding some kind of alert if the medication is not found?
      }
    }
  }, [
    initialValues,
    setValues,
    mode,
    fetchNumbers,
    setFieldValue,
    medications,
    isReprescribing,
    authRxNo,
    scriptNo,
    resetInitialValues,
  ]);

  // Set the prescriber data section of the form. There might be a better solution for this - e.g.
  // setting it elsewhere, but for now this works well
  useEffect(() => {
    if (userDocument) {
      setFieldValue('prescriberData.fullName', userDocument.fullName);
      setFieldValue('prescriberData.prescriberNumber', userDocument.prescriberNumber);
      setFieldValue('prescriberData.qualifications', userDocument.qualifications);
    }
  }, [setFieldValue, userDocument]);

  function clearAuthorityFields() {
    setFieldValue('drugData.authRequired', false);
    setFieldValue('miscData.authCode', '');
    setFieldValue('miscData.justification', '');
    setFieldValue('miscData.prevAuth', false);
    setFieldValue('miscData.age', '');
  }

  function handleSelectMedication(medication: Medication) {
    const { medicinalProductPackName, brandName, isCompoundingRequired } = medication;
    // Update form values common to ALL medications (PBS and non-PBS)
    setFieldValue('medication', medication);
    setFieldValue('drugData.medicationId', medication.id);
    setFieldValue('drugData.activeIngredient', medicinalProductPackName);
    setFieldValue('drugData.brandName', brandName ?? '');
    setFieldValue('drugData.compounded', isCompoundingRequired);

    // 'Reset' certain form values to their default state
    setFieldValue('drugData.indications', '');
    setFieldValue('drugData.substitutePermitted', true);
    setFieldValue('drugData.brandOnly', false);
    setFieldValue('drugData.includeBrand', false);
    setFieldValue('drugData.authRequired', false);
    setFieldValue('drugData.pbsRx', false);

    // Since we are changing the pbsRx status, we should handle any side effects of this change
    handleChangeIsPbsPrescription(false);

    // LEMI/LMBC handling will change some of the default values set above
    if (checkIsPbsMedication(medication)) {
      handleLEMIInfo({
        isLemi: medication.isOnLemi,
        isLmbc: medication.isOnLmbc,
      });
    } else {
      removeLEMIInfo();
    }

    setDrugAlerts((prevAlerts) => ({
      ...prevAlerts,
      ...getPrePbsSelectionDrugAlerts(medication),
    }));

    if (!checkIsPbsMedication(medication)) {
      clearAuthorityFields();
      removeLEMIInfo();
      return;
    }

    // Handle authority form values, as needed
    if (!checkIsAuthorityRequired(medication)) {
      clearAuthorityFields();
    } else {
      const streamlineAuthorityCode = getStreamlineAuthorityCode(medication);
      if (streamlineAuthorityCode) {
        setFieldValue('miscData.authCode', streamlineAuthorityCode.toString());
      }
    }

    if (checkIsSupplyOnly(medication)) {
      // No specific form field required for this, but we should alert the user
      setDrugAlerts((prevAlerts) => ({
        ...prevAlerts,
        pbsRx: {
          message: <SupplyOnlyAlert medication={medication} />,
          kind: 'error',
        },
      }));
    }
  }

  function handleChangeIsPbsPrescription(isPbsPrescription: boolean) {
    const medication = form.values.medication;
    if (!medication) {
      return;
    }

    if (!isPbsPrescription) {
      setDrugAlerts((prevAlerts) => ({
        ...prevAlerts,
        maxQuantity: null,
        maxRepeats: null,
      }));
      clearAuthorityFields();
      return;
    }

    setDrugAlerts((prevAlerts) => ({
      ...prevAlerts,
      ...getPostPbsSelectionDrugAlerts(medication),
    }));

    setMiscAlerts((prevAlerts) => ({
      ...prevAlerts,
      ...getPostPbsSelectionMiscAlerts({
        medication,
        isPbsPrescription,
      }),
    }));

    if (!checkIsAuthorityRequired(medication)) {
      clearAuthorityFields();
    } else {
      setFieldValue('drugData.authRequired', true);
      const streamlineAuthorityCode = getStreamlineAuthorityCode(medication);
      if (streamlineAuthorityCode) {
        setFieldValue('miscData.authCode', streamlineAuthorityCode.toString());
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>New prescription · OptomRx</title>
        <meta
          name="description"
          content="Write a new prescription, with a suite of tools to streamline the process."
        />
        <link rel="canonical" href="/prescription/new" />
      </Helmet>
      <ContentContainer>
        <PageHeader
          title="New prescription"
          description="Complete all sections required for your prescription"
          rightSection={<PrescriberDetails userDocument={userDocument} />}
        />
        <StyledRxForm
          className="rxform"
          onSubmit={form.onSubmit((_, event) => {
            event?.preventDefault();
            navigate('/prescription/review');
          })}
          autoComplete="off"
          noValidate
        >
          <div className={classes.scriptIdContainer} data-testid="scriptNo">
            Script number: {isLoadingNumbers ? 'Loading...' : form.values.miscData.scriptID}
          </div>

          <Fieldset className="prescriber-form select-fieldset" legend="Practice details">
            <PracticeDetails form={form} userId={user.uid} />
          </Fieldset>

          <Fieldset className="patient-form" legend="Patient details">
            <PatientDetails googleLoaded={googleLoaded} form={form} />
          </Fieldset>

          <Fieldset className="drug-form" legend="Medication details">
            {isLoadingMedications ? (
              <MedicationDetailsSkeleton />
            ) : (
              <MedicationDetails
                alerts={drugAlerts}
                setAlerts={setDrugAlerts}
                form={form}
                isIndicationsDefaultExpanded={
                  userDocument?.settings?.isIndicationsDefaultExpanded ?? true
                }
                medications={medications ?? defaultMedications}
                onSelectMedication={handleSelectMedication}
                onChangeIsPbsPrescription={handleChangeIsPbsPrescription}
                lemiComponent={LEMIComponent}
              />
            )}
          </Fieldset>

          <Fieldset className="misc-form" legend="Authority details">
            <AuthorityDetails
              drugAlerts={drugAlerts}
              miscAlerts={miscAlerts}
              numbersLoading={isLoadingNumbers}
              form={form}
            />
          </Fieldset>

          <div className="PrescriberForm__btns">
            <Button type="submit" className="submit-btn button" variant="primary" size="md">
              Generate prescription
            </Button>
            <Button
              component={Link}
              to="/dashboard"
              variant="grey"
              className="cancel-btn"
              size="md"
            >
              Cancel
            </Button>
          </div>
        </StyledRxForm>
      </ContentContainer>
    </>
  );
};

export default RxForm;
