import { Button, TextInput, Modal } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Prescription } from '../../types/firestore';
import { updateDoc, doc } from 'firebase/firestore';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { db } from '../../firebase/config';
import { logError } from '../../utils/logError';
import type { User } from 'firebase/auth';
import baseModalClasses from '../../styles/Modal.module.css';
import { formatPrescribedMedication } from '../../hooks/useFormatting';
import { PRESCRIPTIONS_COLLECTION } from '../../entities/collections';

type AddFavouriteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  user: User;
  prescription: Prescription;
};

type FormValues = {
  customName: string;
};

export const AddFavouriteModal = ({ isOpen, onClose, prescription }: AddFavouriteModalProps) => {
  const [isSavingFavourite, setIsSavingFavourite] = useState(false);

  const form = useForm<FormValues>({
    initialValues: {
      customName: '',
    },
  });

  // Takes current script and adds it to the user's favourites array
  const addToFavourites = async (scriptToAdd: Prescription, customName: string) => {
    // Ensure the scriptData is not null
    if (scriptToAdd) {
      setIsSavingFavourite(true);
      try {
        await updateDoc(doc(db, PRESCRIPTIONS_COLLECTION, scriptToAdd.scriptId), {
          isFavourite: true,
          favouriteName: customName === '' ? formatPrescribedMedication(prescription) : customName,
        });
        setIsSavingFavourite(false);
        onClose();
        toast.success('Prescription saved to favourites');
      } catch (error) {
        setIsSavingFavourite(false);
        onClose();
        toast.error('An error occurred while adding favourites. Please try again.');
        logError(error);
      }
    }
  };

  return (
    <Modal title="Add to favourites" onClose={onClose} opened={isOpen}>
      <form
        onSubmit={form.onSubmit((values, event) => {
          event?.preventDefault();
          addToFavourites(prescription, values.customName);
        })}
      >
        <TextInput
          label="Prescription name (optional)"
          {...form.getInputProps('customName')}
          description="This script will be displayed in your favourites list using this name."
          inputWrapperOrder={['label', 'input', 'description', 'error']}
          placeholder={prescription.activeIngredient}
        />

        <div className={baseModalClasses.buttons}>
          <Button
            className="cancel"
            variant="grey"
            onClick={() => {
              form.reset();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button type="submit" variant="primary" loading={isSavingFavourite}>
            Add
          </Button>
        </div>
      </form>
    </Modal>
  );
};
