import { signOut } from 'firebase/auth';
import { auth } from '../firebase/config';
import { logError } from './logError';
import toast from 'react-hot-toast';

/**
 * Simple wrapper around the Firebase signOut function that logs any errors that occur.
 */
export async function logout() {
  try {
    await signOut(auth);
  } catch (error) {
    toast.error('An error occurred while logging out. Try again.');
    logError(error);
  }
}
