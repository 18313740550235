import { styled } from 'styled-components';

const StyledDrugAutocomplete = styled.div`
  position: relative;
  width: 26rem;

  .items-list {
    position: absolute;
    z-index: 10;
    width: 155%;
    font-size: 0.9rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    overflow-y: auto;
    margin-top: -1.1rem;
    max-height: 280px;

    .item {
      cursor: pointer;
      background-color: #fff;
      padding: 0 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      border-top: 1px solid #e6e6e6;
      width: 100%;
      text-align: left;
      line-height: 1.9rem;

      .item-brand,
      .item-active,
      .item-bold {
        pointer-events: none;
      }
    }

    .item:hover {
      background-color: #e9e9e9;
      overflow: visible;
      white-space: normal;
      color: black;
    }

    .active {
      background-color: dodgerblue;
      color: #fff;
      overflow: visible;
      white-space: normal;
    }

    .list-footer {
      height: 1rem;
    }
  }

  /* Show and hide classes control the sub-fields display (i.e. fields other than autocomplete) */
  .show {
    display: flex;
  }

  .hide {
    display: none !important;
  }

  .show-list {
    display: block;
  }

  .drug-collapse {
    flex-direction: column;
    padding-bottom: 0.85rem;
  }

  .activeIngredient {
    margin-bottom: 1rem;

    .alert-container {
      margin-top: -0.8rem;
    }
  }

  .checkbox {
    margin: 0.5rem 0;
  }

  .compounded {
    margin-bottom: 0.85rem;
  }

  .brandOnly-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 26rem;

    .show {
      display: inline-block;
    }
  }

  .brand-checkbox {
    margin-top: 1rem;
  }

  .expand-btn {
    position: absolute;
    right: 0.3rem;
    top: 5.6rem;
    padding: 0;
    margin: 0;
    height: 16px;

    &:focus {
      outline-offset: 2px;
    }
  }

  .sixty-day-chip {
    background-color: #09893e;
    color: #ffffff;
    font-size: 0.7rem;
    padding: 2px 4px;
    border-radius: 4px;
    pointer-events: none;
    line-height: 1;
    margin-left: 8px;
  }

  /* Specific query as the above items list styling hits a width limit here */
  @media (max-width: 800px) {
    .items-list {
      width: 100%;
    }
  }

  /* Landscape phones/portrait tables */
  @media (max-width: 590px) {
    width: 100%;
    max-width: 26rem;

    .brandOnly-container {
      max-width: 26rem;
      width: 100%;
    }
  }
`;

export { StyledDrugAutocomplete };
