import { ReactNode, createContext, useEffect, useState } from 'react';
import { auth, db } from '../firebase/config';
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
  updateProfile,
} from 'firebase/auth';
import type { User } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { UserDocument } from '../types/firestore';

export type AuthContext = {
  signup: (signupFormValues: {
    email: string;
    password: string;
    fullName: string;
    prescriberNumber: string;
    qualifications: string;
  }) => Promise<void>;
  user: User | null;
  authIsReady: boolean;
  isAdmin: boolean;
  isVerificationSent: boolean;
  setIsVerificationSent: React.Dispatch<React.SetStateAction<boolean>>;
};

async function checkIsAdmin(user: User | null) {
  if (!user) {
    return false;
  }
  const idTokenResult = await user.getIdTokenResult();
  return Boolean(idTokenResult.claims.isAdmin);
}

// Exports the context object itself. This context object is retrieved with the useContext react hook, and allows use to destructure certain state props (such as user)
export const AuthContext = createContext<AuthContext | null>(null);

// ContextProvider component that wraps the application and affords access to global auth state and functions
export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [authIsReady, setAuthIsReady] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isVerificationSent, setIsVerificationSent] = useState(false);

  const signup = async ({
    email,
    password,
    fullName,
    prescriberNumber,
    qualifications,
  }: {
    email: string;
    password: string;
    fullName: string;
    prescriberNumber: string;
    qualifications: string;
  }) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);

    // Create user document in Firestore
    await setDoc(doc(db, 'users', userCredential.user.uid), {
      fullName,
      prescriberNumber,
      qualifications,
      settings: {
        isIndicationsDefaultExpanded: true,
      },
    } satisfies UserDocument);

    // Update AUTH user's display name. We use this display name for the time being, but may end
    // up using the full name from the user document in the future.
    await updateProfile(userCredential.user, {
      displayName: fullName,
    });

    // Send email verification upon first sign up only
    await sendEmailVerification(userCredential.user);
    setIsVerificationSent(true);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      const isAdmin = await checkIsAdmin(user);
      setIsAdmin(isAdmin);
      setAuthIsReady(true);
    });
    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        authIsReady,
        isVerificationSent,
        setIsVerificationSent,
        signup,
        isAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
