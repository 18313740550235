import { styled } from 'styled-components';

const StyledRxForm = styled.form`
  position: relative;

  .add-new-btn {
    max-width: 190px;
  }

  .prescriber-form {
    border-top: 1px solid #dfe1e1;
  }

  .proda-link {
    font-size: 0.8rem;
    color: var(--neutral);
  }

  .misc-form {
    .authRxNo {
      margin: 0.75rem 0 0.75rem 0;
      padding: 0;
      font-size: 0.9rem;
    }

    .retention {
      display: flex;
      flex-direction: column;
      .age-field {
        margin: 0.85rem 0;

        input {
          width: 5rem;
        }
      }

      .prevAuth {
        position: relative;
      }
    }
  }

  .medicareFields {
    display: flex;
    position: relative;
  }

  .prefix-field {
    margin: 0 0 1.25rem 0;
  }

  .prescriberNo-field,
  .phoneNo-field {
    width: 10rem;
  }

  .pbsRx {
    margin: 1.25rem 0 1.5rem 0;
  }

  .PrescriberForm__btns {
    padding: 2.75rem 0 0 20rem;
    width: 100%;
    display: flex;
    align-items: center;

    .submit-btn {
      margin-right: 1.5rem;
    }
  }

  /* Landscape phones and down */
  @media (max-width: 920px) {
    .PrescriberForm__btns {
      padding: 2.75rem 0 0 0rem;
    }
  }

  @media (max-width: 768px) {
    .scriptNo {
      margin-top: 0;
    }
  }

  /* Landscape phones and down */
  @media (max-width: 590px) {
    .misc-form .retention .justification-field {
      max-width: 26rem;
      width: 100%;
    }
  }

  /* Portrait phones */
  @media (max-width: 450px) {
    .PrescriberForm__btns {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      button,
      a {
        width: 90%;
        margin: 0;
        text-align: center;
      }

      .submit-btn {
        margin-bottom: 1.5rem;
        margin-right: 0;
      }
    }
  }
`;

export { StyledRxForm };
