import { TextInputSkeleton } from '../utils/Skeletons/TextInputSkeleton';
import { CheckboxInputSkeleton } from '../utils/Skeletons/CheckboxInputSkeleton';

export function MedicationDetailsSkeleton() {
  return (
    <div>
      <TextInputSkeleton />
      <TextInputSkeleton />
      <CheckboxInputSkeleton />
      <CheckboxInputSkeleton />
      <CheckboxInputSkeleton />
      <CheckboxInputSkeleton />
      <TextInputSkeleton />
      <CheckboxInputSkeleton inputWidth={150} />
      <TextInputSkeleton labelWidth={60} inputWidth={85} />
      <TextInputSkeleton labelWidth={60} inputWidth={85} />
    </div>
  );
}
