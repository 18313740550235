import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectAuthEmulator, getAuth } from 'firebase/auth';

const productionFirebaseConfig = {
  apiKey: 'AIzaSyBvzkDigW4wgRvq0vEbs3M8COZD9ZrbY3Q',
  authDomain: 'optom-rx-dcb2e.firebaseapp.com',
  projectId: import.meta.env.VITE_PRODUCTION_FIREBASE_PROJECT_ID,
  storageBucket: 'optom-rx-dcb2e.appspot.com',
  messagingSenderId: '1057936692796',
  appId: '1:1057936692796:web:851c581c23ee6300db1539',
  measurementId: 'G-F26B2MTB8F',
};

const developmentFirebaseConfig = {
  // Firebase Auth needs an API key, even in demo emulation
  apiKey: 'fake-api-key',
  // This must be prefixed with 'demo-' to have Firebase emulator use 'demo' mode
  projectId: import.meta.env.VITE_DEVELOPMENT_FIREBASE_PROJECT_ID,
};

const isProduction = import.meta.env.PROD;

const app = initializeApp(isProduction ? productionFirebaseConfig : developmentFirebaseConfig);

// Firebase emulator doesn't support AppCheck, so while the app will work in development, it will
// just litter the console with errors if AppCheck is enabled.
if (isProduction) {
  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(import.meta.env.VITE_RECAPTCHA_SITE_KEY),
    isTokenAutoRefreshEnabled: true,
  });
}

const db = getFirestore(app);

const auth = getAuth(app);

// Connect to Firestore emulator if in development so all requests are sent to the emulator
if (!isProduction) {
  connectFirestoreEmulator(db, '127.0.0.1', 8080);
  connectAuthEmulator(auth, 'http://127.0.0.1:9099');
}

export { db, auth };
