import { StyledFAQ } from './FAQ.styled';
import { Helmet } from 'react-helmet-async';
import { LandingPageContainer } from '../utils/LandingPageContainer/LandingPageContainer';
import autocompleteScreenshot from '../../assets/autocomplete-screenshot-old.png';
import practicesScreenshot from '../../assets/practices-screenshot-new.png';
import { Anchor } from '@mantine/core';

const FAQ = () => {
  return (
    <>
      <Helmet>
        <title>FAQ · OptomRx</title>
        <meta
          name="description"
          content="Read through a collection of common questions about OptomRx, and learn more about how it can help you write faster, better prescriptions."
        />
        <link rel="canonical" href="/faq" />
      </Helmet>
      <LandingPageContainer>
        <StyledFAQ>
          <h2 className="FAQ__heading">Frequently Asked Questions</h2>

          <div className="FAQ__section">
            <ul className="FAQ__list">
              <li className="FAQ__list-item">
                <Anchor href="#Q1" className="FAQ__link">
                  What is OptomRx?
                </Anchor>
              </li>
              <li className="FAQ__list-item">
                <Anchor href="#Q2" className="FAQ__link">
                  Is OptomRx free to use?
                </Anchor>
              </li>
              <li className="FAQ__list-item">
                <Anchor href="#Q3" className="FAQ__link">
                  How do I get started with OptomRx?
                </Anchor>
              </li>
              <li className="FAQ__list-item">
                <Anchor href="#Q4" className="FAQ__link">
                  How do I order script paper for my printer?
                </Anchor>
              </li>
              <li className="FAQ__list-item">
                <Anchor href="#Q5" className="FAQ__link">
                  Can I use OptomRx anywhere I work?
                </Anchor>
              </li>
              <li className="FAQ__list-item">
                <Anchor href="#Q6" className="FAQ__link">
                  How is patient information handled in OptomRx?
                </Anchor>
              </li>
              <li className="FAQ__list-item">
                <Anchor href="#Q7" className="FAQ__link">
                  Can I add multiple practices to my OptomRx account?
                </Anchor>
              </li>
              <li className="FAQ__list-item">
                <Anchor href="#Q8" className="FAQ__link">
                  Can I have multiple prescribers on one account?
                </Anchor>
              </li>
              <li className="FAQ__list-item">
                <Anchor href="#Q9" className="FAQ__link">
                  How can I find a script that I&apos;ve written for a certain patient?
                </Anchor>
              </li>
              <li className="FAQ__list-item">
                <Anchor href="#Q10" className="FAQ__link">
                  How do I keep records of scripts I&apos;ve written for auditing purposes?
                </Anchor>
              </li>
              <li className="FAQ__list-item">
                <Anchor href="#Q11" className="FAQ__link">
                  Can I use OptomRx to write non-optometric prescriptions?
                </Anchor>
              </li>
              <li className="FAQ__list-item">
                <Anchor href="#Q12" className="FAQ__link">
                  How do I request a new medication?
                </Anchor>
              </li>
              <li className="FAQ__list-item">
                <Anchor href="#Q13" className="FAQ__link">
                  I want to suggest a feature or report an error. How to I go about doing this?
                </Anchor>
              </li>
            </ul>
          </div>

          <div className="FAQ__responses">
            <article className="FAQ__response">
              <h3 id="Q1" className="FAQ__question-title">
                What is OptomRx?
              </h3>
              <p className="FAQ__paragraph">
                OptomRx is a website that allows you to quickly and easily write therapeutic
                prescriptions for your patients.
              </p>
              <p className="FAQ__paragraph">
                With all PBS and private prescribable medications in our database, there&apos;s no
                need to worry about forgetting the authority code for HYLO-FORTE®, or the specific
                percentages of your glaucoma drops. OptomRx has up-to-date information that
                pre-loads into your prescription as you fill it.
              </p>
              <img
                src={autocompleteScreenshot}
                alt="Autocomplete suggestions for 'xa', including Xalatan, Xalaprost, Xalacom, and Xalamol"
              />
            </article>

            <article className="FAQ__response">
              <h3 id="Q2" className="FAQ__question-title">
                Is OptomRx free to use?
              </h3>
              <p className="FAQ__paragraph">
                Yes! OptomRx is completely free to use and there is no limit on the number of
                prescriptions you can write.
              </p>
            </article>

            <article className="FAQ__response">
              <h3 id="Q3" className="FAQ__question-title">
                How do I get started with OptomRx?
              </h3>
              <p className="FAQ__paragraph">
                Getting started is quick and easy! There are just two important steps to get set up
                with OptomRx.
              </p>
              <p className="FAQ__paragraph">
                Firstly, follow this link to{' '}
                <Anchor href="/signup" target="_blank" rel="noopener noreferrer">
                  create a new account
                </Anchor>
                . This process takes less than a minute and your profile will be ready for
                prescribing.
              </p>
              <p className="FAQ__paragraph">
                Secondly{' '}
                <Anchor
                  href="https://www.servicesaustralia.gov.au/order-pbs-and-rpbs-stationery?context=20"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  order your script paper
                </Anchor>{' '}
                for printing. This is free to do and can be done through Services Australia. More
                details can be found in the next FAQ if needed.
              </p>
              <p className="FAQ__paragraph">
                That&apos;s all! Completing these two small steps means you&apos;re officially ready
                to jump in and start prescribing!
              </p>
            </article>

            <article className="FAQ__response">
              <h3 id="Q4" className="FAQ__question-title">
                How do I order script paper for my printer?
              </h3>
              <p className="FAQ__paragraph">
                Script paper is free to order to all registered optometrists. Firstly, follow this
                link which will take you to the{' '}
                <Anchor
                  href="https://www.servicesaustralia.gov.au/order-pbs-and-rpbs-stationery?context=20"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Services Australia stationary page.
                </Anchor>{' '}
                Scroll down and click the &quot;How prescribers can order PBS stationary&quot;
                drop-down box.
              </p>
              <p className="FAQ__paragraph">
                The easiest method of ordering stationary is online via HPOS. Click the &quot;log in
                to HPOS using PRODA&quot; link and sign in to PRODA.
              </p>
              <p className="FAQ__paragraph">
                Here, click on the &quot;My programs&quot; tile and then click the &quot;PBS
                Stationary Online Ordering&quot; tile. Select &quot;New Order&quot; in the bottom
                left corner and follow the prompts.
              </p>
              <p className="FAQ__paragraph">
                In the unlikely event that you don&apos;t have a PRODA account linked to HPOS,
                script paper can also be ordered by filling in{' '}
                <Anchor
                  href="https://www.servicesaustralia.gov.au/pb229?context=41451"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  this paper form
                </Anchor>{' '}
                and sending it off instead.
              </p>
            </article>

            <article className="FAQ__response">
              <h3 id="Q5" className="FAQ__question-title">
                Can I use OptomRx anywhere I work?
              </h3>
              <p>
                Absolutely! One of the best features of OptomRx is the ability to add multiple
                practices to a single account. You can simply select which practice you&apos;re
                prescribing from using the drop-down menu when entering a new script.
              </p>
              <p>You can also set your main practice as a favourite if you wish!</p>
              <img
                src={practicesScreenshot}
                alt="Web UI for managing multiple practices in OptomRx, including adding a new practice and setting a practice as 'favourite'"
              />
            </article>

            <article className="FAQ__response">
              <h3 id="Q6" className="FAQ__question-title">
                How is patient information handled in OptomRx?
              </h3>
              <p>
                Security is very important to us here at OptomRx. Because of this, we will{' '}
                <strong>never</strong> store patient data of any kind.
              </p>
              <p>
                With an increase in data breaches over recent years, particularly in the healthcare
                and medical fields, we decided to take the only zero-risk approach and only store
                script data, never patient data.
              </p>
            </article>

            <article className="FAQ__response">
              <h3 id="Q7" className="FAQ__question-title">
                Can I add multiple practices to my OptomRx account?
              </h3>
              <p className="FAQ__paragraph">
                Absolutely! You can have an unlimited number of practices on your OptomRx account,
                allowing you to prescribe therapeutic scripts from anywhere in Australia.
              </p>
            </article>

            <article className="FAQ__response">
              <h3 id="Q8" className="FAQ__question-title">
                Can I have multiple prescribers on one account?
              </h3>
              <p className="FAQ__paragraph">
                No, only one prescriber is allowed per account. However, you are permitted to have
                unlimited practices linked to that prescriber.
              </p>
            </article>

            <article className="FAQ__response">
              <h3 id="Q9" className="FAQ__question-title">
                How can I find a script that I&apos;ve written for a certain patient?
              </h3>
              <p className="FAQ__paragraph">
                Each script you write has a unique Script ID attached to it. Many existing users
                simply copy and paste this into their patient notes at the time of writing as a way
                of connecting their scripts to their patients.
              </p>
              <p className="FAQ__paragraph">
                As a backup, each script is also timestamped, so if you accidentally forget to add
                the Script ID to your patient notes, you can simply go to the date your script was
                written and select the prescription that aligns with the time your patient was seen.
              </p>
              <p className="FAQ__paragraph">
                All of this means that your patient data is as secure as can be, whilst your scripts
                are still traceable should they ever be needed in future.
              </p>
            </article>

            <article className="FAQ__response">
              <h3 id="Q10" className="FAQ__question-title">
                How do I keep records of scripts I&apos;ve written for auditing purposes?
              </h3>
              <p className="FAQ__paragraph">
                All scripts generated on OptomRx give the option of printing a physical prescriber
                copy. This will print on the bottom-left tear-off section of the PBS script paper.
                Details pertaining to PBS authority scripts will also be included here.
              </p>
            </article>

            <article className="FAQ__response">
              <h3 id="Q11" className="FAQ__question-title">
                Can I use OptomRx to write non-optometric prescriptions?
              </h3>
              <p className="FAQ__paragraph">
                No. OptomRx does not currently support non-optometry prescription-writing.
              </p>
            </article>

            <article className="FAQ__response">
              <h3 id="Q12" className="FAQ__question-title">
                How do I request a new medication?
              </h3>
              <p className="FAQ__paragraph">
                Got a medication that you can&apos;t find on the list? Firstly, try the brand name
                and the medication name to ensure there aren&apos;t differences in spelling. If your
                medication still can&apos;t be found, you can request an addition by{' '}
                <Anchor href="mailto:mail@optomrx.com.au">sending us an email.</Anchor>
              </p>
            </article>

            <article className="FAQ__response">
              <h3 id="Q13" className="FAQ__question-title">
                I want to suggest a feature or report an error. How to I go about doing this?
              </h3>
              <p className="FAQ__paragraph">
                You can report a bug or suggest a feature by contacting us directly via our
                dedicated email address. We are always happy to receive feedback and we constantly
                strive to make improvements in both usability and efficiency. Contact us at{' '}
                <Anchor href="mailto:mail@optomrx.com.au">mail@optomrx.com.au</Anchor> if
                you&apos;ve got a suggestion!
              </p>
            </article>
          </div>
        </StyledFAQ>
      </LandingPageContainer>
    </>
  );
};

export default FAQ;
