import { Button, Modal, Alert } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import classes from './DeleteFavouriteModal.module.css';
import baseModalClasses from '../../styles/Modal.module.css';
import { Prescription } from '../../types/firestore';
import { formatPrescribedMedication } from '../../hooks/useFormatting';

type DeleteFavouriteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  favourite: Prescription;
};

/**
 * Modal to confirm deletion of a favourite.
 */
export const DeleteFavouriteModal = ({
  isOpen,
  onClose,
  onDelete,
  favourite,
}: DeleteFavouriteModalProps) => {
  return (
    <Modal title="Delete favourite" onClose={onClose} opened={isOpen}>
      <Alert variant="light" icon={<IconInfoCircle />} color="red" title="Attention">
        This will permanently delete the following favourite
      </Alert>
      <div className={classes.selectedFavouriteContainer}>
        <div className={classes.selectedFavouriteLabel}>Selected favourite</div>
        <div>{favourite.favouriteName ?? formatPrescribedMedication(favourite)}</div>
      </div>

      <div className={baseModalClasses.buttons}>
        <Button className="cancel" variant="grey" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onDelete}>
          Delete
        </Button>
      </div>
    </Modal>
  );
};
