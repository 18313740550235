import { StyledScripts } from './Scripts.styled';
import ScriptsTable from './ScriptsTable';
import Spinner from '../utils/Spinner/Spinner';
import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import { Helmet } from 'react-helmet-async';
import type { User } from 'firebase/auth';
import { prescriptionSchema } from '../../types/firestore';
import { useHandleFetchError } from '../../hooks/useHandleFetchError';
import { useCollection } from '../../hooks/useCollection';
import { where } from 'firebase/firestore';
import { useCallback, useMemo } from 'react';
import { PRESCRIPTIONS_COLLECTION } from '../../entities/collections';

type ScriptsProps = {
  user: User;
};

const Scripts = ({ user }: ScriptsProps) => {
  const {
    data: prescriptions,
    error: prescriptionsError,
    isLoading: isLoadingPrescriptions,
  } = useCollection(PRESCRIPTIONS_COLLECTION, prescriptionSchema, {
    getQueryConstraints: useCallback(() => [where('userDocumentId', '==', user.uid)], [user.uid]),
  });

  useHandleFetchError(prescriptionsError);

  const reversedPrescriptions = useMemo(() => {
    return prescriptions ? [...prescriptions].reverse() : [];
  }, [prescriptions]);

  function getContent() {
    if (isLoadingPrescriptions) {
      return <Spinner />;
    }

    if (prescriptionsError || !prescriptions || prescriptions.length === 0) {
      return (
        <table className="table table-none">
          <thead className="tableRowHeader">
            <tr>
              <th className="tableHeader">Script ID</th>
              <th className="tableHeader">Medication</th>
              <th className="tableHeader">Date prescribed</th>
            </tr>
          </thead>
          <tbody>
            <tr className="tableRowItems">
              <td className="tableCellNone" colSpan={3}>
                No scripts written yet
              </td>
            </tr>
          </tbody>
        </table>
      );
    }

    if (!prescriptions || prescriptions.length === 0) {
      return <p>No scripts written yet</p>;
    }

    return <ScriptsTable scripts={reversedPrescriptions} scriptsPerPage={15} />;
  }

  return (
    <>
      <Helmet>
        <title>Scripts · OptomRx</title>
        <meta
          name="description"
          content="View all the prescriptions you have written. Saved scripts contain all medication details for that script, but no patient data."
        />
        <link rel="canonical" href="/dashboard" />
      </Helmet>
      <ContentContainer earlyPadding={true}>
        <StyledScripts className="Scripts">
          <PageHeader
            title="My scripts"
            description="Review previous prescriptions you have written"
          />

          <div className="Scripts__container">{getContent()}</div>
        </StyledScripts>
      </ContentContainer>
    </>
  );
};

export default Scripts;
