// Importing styles here helps keep Mantine's global styles at a low specificity
import '@mantine/core/styles.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { HelmetProvider } from 'react-helmet-async';
import { createRoot } from 'react-dom/client';
import ErrorBoundary from './components/ErrorBoundary/BugsnagErrorBoundary';
import { FallbackErrorBoundary } from './components/ErrorBoundary/FallbackErrorBoundary';
import { PrescriptionInitialValuesProvider } from './context/PrescriptionInitialValuesContext';
import { MedicationsProvider } from './context/MedicationsContext';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={FallbackErrorBoundary}>
      <HelmetProvider>
        <BrowserRouter>
          <AuthContextProvider>
            <MedicationsProvider>
              <PrescriptionInitialValuesProvider>
                <App />
              </PrescriptionInitialValuesProvider>
            </MedicationsProvider>
          </AuthContextProvider>
        </BrowserRouter>
      </HelmetProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);
