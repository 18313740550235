import React, { createContext, useContext, useState, ReactNode } from 'react';
import { PrescriptionFormValues } from '../types/firestore';

const currentDate = new Date().toISOString().split('T')[0];

export const initialPrescriptionFormValues: PrescriptionFormValues = {
  medication: null,
  drugData: {
    activeIngredient: '',
    brandName: '',
    quantity: '',
    repeats: '',
    dosage: '',
    verified: false,
    indications: '',
    substitutePermitted: true,
    brandOnly: false,
    includeBrand: false,
    pbsRx: false,
    compounded: false,
    authRequired: false,
    medicationId: null,
  },
  patientData: {
    fullName: '',
    streetAddress: '',
    subpremise: '',
    suburb: '',
    postcode: '',
    state: '',
    medicareNumber: '',
    medicareRefNumber: '',
  },
  practiceData: {
    prefix: false,
    practiceName: '',
    streetAddress: '',
    subpremise: '',
    suburb: '',
    postcode: '',
    state: '',
    phoneNumber: '',
    default: false,
  },
  miscData: {
    authRxNumber: '',
    authCode: '',
    scriptID: '',
    justification: '',
    prevAuth: false,
    age: '',
    date: currentDate,
  },
  prescriberData: {
    fullName: '',
    qualifications: '',
    prescriberNumber: '',
  },
};

/**
 * Context for storing the initial prescription form values.
 * This context is intended solely for initialising the  RxForm component
 * when creating a new prescription (or represcribing), not for tracking every change.
 */
const PrescriptionInitialValuesContext = createContext<{
  initialValues: PrescriptionFormValues;
  setInitialValues: React.Dispatch<React.SetStateAction<PrescriptionFormValues>>;
  resetInitialValues: () => PrescriptionFormValues;
} | null>(null);

export const PrescriptionInitialValuesProvider = ({ children }: { children: ReactNode }) => {
  const [initialValues, setInitialValues] = useState<PrescriptionFormValues>(
    initialPrescriptionFormValues,
  );

  const resetInitialValues = () => {
    setInitialValues(initialPrescriptionFormValues);
    return initialPrescriptionFormValues;
  };

  return (
    <PrescriptionInitialValuesContext.Provider
      value={{ initialValues, setInitialValues, resetInitialValues }}
    >
      {children}
    </PrescriptionInitialValuesContext.Provider>
  );
};

export const usePrescriptionInitialValues = () => {
  const context = useContext(PrescriptionInitialValuesContext);
  if (!context) {
    throw new Error(
      'usePrescriptionInitialValues must be used within a PrescriptionInitialValuesProvider',
    );
  }
  return context;
};
