import { useState } from 'react';
import { auth } from '../firebase/config';
import { setPersistence, signInWithEmailAndPassword } from 'firebase/auth';
import type { Persistence } from 'firebase/auth';
import { useErrorHandling } from './useErrorHandling';
import { FirebaseError } from 'firebase/app';
import { logError } from '../utils/logError';

// Custom hook to handle user signing in
export const useLogin = () => {
  // Error can be passed to UI elements as needed
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);
  const { handleLoginSignupError } = useErrorHandling();

  const login = async (email: string, password: string, persistence: Persistence) => {
    setError(null);
    setIsPending(true);

    try {
      await setPersistence(auth, persistence);
      await signInWithEmailAndPassword(auth, email, password);
      setIsPending(false);
    } catch (err) {
      setIsPending(false);
      if (err instanceof FirebaseError) {
        const errorMsg = handleLoginSignupError(err.code);
        setError(errorMsg);
      } else {
        logError(err);
      }
    }
  };

  return { error, login, isPending, setError };
};
