import { Tooltip } from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';
import classes from './RxForm.module.css';
import { Medication } from '../../types/medication';

/**
 * An 'alert message' component used to display a tooltip with a description of the
 * 'Supply only' label for PBS medicines.
 */
export function SupplyOnlyAlert({ medication }: { medication: Medication }) {
  return (
    <div className={classes.supplyOnlyAlertContainer}>
      <span>This item is supply only</span>
      <Tooltip
        label={`Products that are in a Supply Only state will be available for dispensing, but not
          for prescribing. PBS Prescriptions written prior to the Supply Only period 
          (${medication.pbsDetails?.supply_only_date}) are able to be dispensed using either the
          Supply Only product where available, or an equivalent product
          as specified on the PBS schedule.`}
        className={classes.supplyOnlyTooltip}
      >
        <IconHelp
          aria-label="Show full description for 'Supply only'"
          width={18}
          height={18}
          className={classes.helpIcon}
        />
      </Tooltip>
    </div>
  );
}
