import { Helmet } from 'react-helmet-async';
import Subheading from '../utils/Subheading/Subheading';
import { Checkbox, Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import { updateDoc, doc } from 'firebase/firestore';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { db } from '../../firebase/config';
import { UserDocumentWithId } from '../../types/firestore';
import { handleCheckboxEnterPress } from '../../utils/formUtils';
import classes from './Settings.module.css';
import { SettingsPage } from './Profile';
import { logError } from '../../utils/logError';
import { USERS_COLLECTION } from '../../entities/collections';

export type PreferencesFormValues = {
  isIndicationsDefaultExpanded: boolean;
};

export const Preferences = ({ userDocument }: { userDocument: UserDocumentWithId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const initialFormValues: PreferencesFormValues = {
    isIndicationsDefaultExpanded: userDocument.settings.isIndicationsDefaultExpanded,
  };

  const form = useForm<PreferencesFormValues>({
    initialValues: {
      ...initialFormValues,
    },
  });

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await updateDoc(doc(db, USERS_COLLECTION, userDocument.id), {
        'settings.isIndicationsDefaultExpanded': form.values.isIndicationsDefaultExpanded,
      });
      toast.success('Preferences saved');
    } catch (error) {
      toast.error('An error occurred while saving preferences. Please try again.');
      logError(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Helmet>
        <title>Preferences · OptomRx</title>
        <meta name="account" content="Adjust your app preferences." />
        <link rel="canonical" href="/settings/preferences" />
      </Helmet>
      <SettingsPage>
        <Subheading text="Preferences" />
        <form className="displayName-form">
          <div className={classes.preferencesFieldset}>
            <Checkbox
              label="Show restricted benefits by default"
              onKeyDown={handleCheckboxEnterPress}
              {...form.getInputProps('isIndicationsDefaultExpanded', { type: 'checkbox' })}
            />
          </div>
          <Button
            variant="primary"
            onClick={handleSave}
            loading={isLoading}
            className={classes.preferencesSaveButton}
          >
            Save
          </Button>
        </form>
      </SettingsPage>
    </>
  );
};
