import { Routes, Route, Navigate } from 'react-router-dom';
import RxForm from '../RxForm/RxForm';
import RxTemplate from '../RxTemplate/RxTemplate';
import type { User } from 'firebase/auth';
import { PrescriptionFormValues } from '../../types/firestore';
import NotFound from '../NotFound/NotFound';
import { isNotEmpty, useForm } from '@mantine/form';
import { isQuantityValid, isRepeatsValid, validateRequiredInput } from '../../utils/formUtils';
import { initialPrescriptionFormValues } from '../../context/PrescriptionInitialValuesContext';

type PrescriptionRoutesProps = {
  user: User;
  googleLoaded: boolean;
};

export const PrescriptionRoutes = ({ user, googleLoaded }: PrescriptionRoutesProps) => {
  const form = useForm<PrescriptionFormValues>({
    initialValues: initialPrescriptionFormValues,
    validate: {
      patientData: {
        fullName: isNotEmpty('This field cannot be left blank'),
        streetAddress: isNotEmpty('This field cannot be left blank'),
        suburb: isNotEmpty('This field cannot be left blank'),
        postcode: isNotEmpty('This field cannot be left blank'),
        state: isNotEmpty('This field cannot be left blank'),
        medicareNumber: (value, values) => {
          const medicareNumber = value.trim();
          const referenceNumber = values.patientData.medicareRefNumber.trim();

          // User entered a reference number but didn't supply a Medicare number. Not allowed!
          if (referenceNumber !== '' && medicareNumber === '') {
            return 'Please enter a Medicare number';
          }

          // Validate the Medicare number as a solo value, if provided
          if (medicareNumber !== '') {
            return /^[0-9]{10}$/.test(medicareNumber)
              ? null
              : 'Medicare number must be exactly 10 digits long';
          }

          // If no Medicare number or reference number is provided, validation passes
          return null;
        },
        medicareRefNumber: (value, values) => {
          const referenceNumber = value.trim();
          const medicareNumber = values.patientData.medicareNumber.trim();
          // User entered a Medicare number but didn't supply a reference number. Not allowed!
          if (medicareNumber !== '' && referenceNumber === '') {
            return 'Please enter a reference number';
          }

          // Validate the reference number as a solo value, if provided
          if (referenceNumber !== '') {
            return /^[1-9]{1}$/.test(referenceNumber)
              ? null
              : 'Reference number must be a single digit from 1-9';
          }

          // If no Medicare number or reference number is provided, validation passes
          return null;
        },
      },
      drugData: {
        activeIngredient: isNotEmpty('This field cannot be left blank'),
        dosage: isNotEmpty('This field cannot be left blank'),
        quantity: (value) => isQuantityValid(value),
        repeats: (value) => isRepeatsValid(value),
        brandName: (value, values) => {
          if (values.drugData.includeBrand) {
            return validateRequiredInput(
              value,
              "Brand name cannot be empty when 'Include brand name on prescription' is selected",
            );
          }
        },
      },
      miscData: {
        date: isNotEmpty('Please enter the current date'),
        authCode: (value, values) => {
          if (values.drugData.authRequired) {
            return validateRequiredInput(
              value,
              'An authority code must be provided for this medication',
            );
          }
        },
      },
    },
    validateInputOnBlur: true,
  });

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <Routes>
      <Route
        path="new"
        element={<RxForm googleLoaded={googleLoaded} user={user} form={form} mode="new" />}
      />
      <Route
        path="edit"
        element={<RxForm googleLoaded={googleLoaded} user={user} form={form} mode="edit" />}
      />
      <Route path="review" element={<RxTemplate form={form} user={user} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
