import { styled } from 'styled-components';

const StyledIndications = styled.div`
  /* Concerns the indication text itself from the PBS (dot points and main criteria) */
  .Indications__content {
    overflow: hidden;
    font-size: 0.9rem;
    color: #48515b;

    a {
      color: var(--neutral);
    }

    .Indication__extra {
      padding: 0.5rem 0 0 0;

      ul {
        list-style-type: square;
        padding-left: 1.5rem;
        margin: 0;

        li {
          padding: 0.75rem 0 0 0.2rem;
        }
      }

      .Indication__clinical {
        font-weight: bold;
        padding: 0.75rem 0.5rem 0.25rem 0rem;
      }
      .Indication__and {
        font-weight: bold;
        padding: 0.75rem 0.5rem 0.25rem 0rem;
        margin-left: -1.5rem;
      }
    }
  }
`;

export { StyledIndications };
