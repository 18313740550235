import { db } from '../firebase/config';
import { useCallback, useState } from 'react';
import { doc, runTransaction } from 'firebase/firestore';
import { numberDocumentSchema } from '../types/firestore';
import { logError } from '../utils/logError';
import toast from 'react-hot-toast';
import { PRESCRIPTION_NUMBERS_COLLECTION } from '../entities/collections';

// Correctly increment the base auth Rx number to be updated on the backend
export const incrementScriptNumber = (prevNumber: string) => {
  if (!/^\d+$/.test(prevNumber)) {
    throw new Error('Invalid input: Base number must be numeric');
  }
  // First convert to base 10
  const base10 = parseInt(prevNumber, 10);
  const incremented = base10 + 1;
  let newNum = incremented.toString();

  // Ensure the length meets the arbitrarily chosen 8 digits by adding leading zeroes until no longer applicable
  // This ensures around 800 years' worth of prescriptions before incrementing to a 9 digit number, for interest :)
  while (newNum.length < 8) {
    newNum = '0' + newNum;
  }

  // If the number exceeds 8 digits,handle as needed
  if (newNum.length > 8) {
    // FIXME: Actually handle this in a reasonable way
    throw new Error('Exceeded the maximum allowable script number');
  }
  return newNum;
};

// Correctly increment the base auth Rx number to be updated on the backend
export const incrementAuthRxNumber = (prevNumber: string) => {
  if (!/^\d+$/.test(prevNumber)) {
    throw new Error('Invalid input: Base number must be numeric');
  }
  // Ensure the counter is 'reset' to 0000000 if the previous number is at the theoretical limit of 9999999
  if (prevNumber === '9999999') {
    return '0000000';
  }

  // First convert to base 10
  const base10 = parseInt(prevNumber, 10);
  const incremented = base10 + 1;
  let newNum = incremented.toString();

  // Ensure the length is at required seven by adding leading zeroes as needed
  while (newNum.length < 7) {
    newNum = '0' + newNum;
  }
  return newNum;
};

// Take any seven digit base number and convert it to a valid PBS authority prescription number
export const generateAuthRxNumber = (baseNumber: string | number) => {
  const baseNumberStr = typeof baseNumber === 'number' ? baseNumber.toString() : baseNumber;

  if (baseNumberStr.length !== 7) {
    throw new Error('Invalid input: Base number be exactly 7 digits long');
  }

  // AuthNo format must be a 7 digit number followed by a check digit that is the remainder of dividing the sum of the digits of the base number by 9
  const reducer = (previousValue: number, currentValue: string): number => {
    return previousValue + parseInt(currentValue, 10);
  };
  const sum = baseNumberStr.split('').reduce(reducer, 0);
  const checkDigit = sum % 9;

  return `${baseNumberStr}${checkDigit}`;
};

// A hook that fetches the current script number from the backend, using a single getDoc call (as opposed to snapshot real time updates), and subsequently increments this number and updates the backend ready for subsequent calls
export const useNumbers = (): {
  scriptNo: string;
  authRxNo: string;
  isLoadingNumbers: boolean;
  numbersError: unknown;
  fetchNumbers: () => Promise<[void, void]>;
} => {
  const [scriptNo, setScriptNo] = useState('');
  const [authRxNo, setAuthRxNo] = useState('');
  const [isLoadingNumbers, setIsLoadingNumbers] = useState(false);
  const [numbersError, setNumbersError] = useState<unknown>(null);

  const fetchNumbers = useCallback(() => {
    // Initialise error and loading state
    setIsLoadingNumbers(true);
    setNumbersError(null);

    // Note there will only ever be one 'current' field in each document in this collection. There are only two documents: scriptNo and authRxNo. They should never need to be called separately.
    const scriptNoRef = doc(db, PRESCRIPTION_NUMBERS_COLLECTION, 'scriptNo');
    const authRxNoRef = doc(db, PRESCRIPTION_NUMBERS_COLLECTION, 'authRxNo');

    const scriptTransaction = runTransaction(db, (transaction) => {
      // First read the database for current values of number (first step of transaction)
      return transaction.get(scriptNoRef).then((response) => {
        const responseData = response.data();
        const numberDocumentData = numberDocumentSchema.parse(responseData);
        // Once read completes, perform logic to modify value
        const newScriptNo = incrementScriptNumber(numberDocumentData.current);
        // Perform the update part of the transaction (second step)
        transaction.update(scriptNoRef, { current: newScriptNo });
        // Here return the newly generated number. Should this promise resolve, this value will become available to the app
        return newScriptNo;
      });
    })
      .then((newScriptNo) => {
        // Once both actions/steps are complete, the promise resolves and is captured in this function here
        setScriptNo(newScriptNo);
      })
      .catch((error) => {
        setNumbersError(error);
        logError(error);
        toast.error('Unable to generate script number. Please try creating a new script.');
      })
      .finally(() => {
        setIsLoadingNumbers(false);
      });

    const authTransaction = runTransaction(db, (transaction) => {
      return transaction.get(authRxNoRef).then((response) => {
        const responseData = response.data();
        const numberDocumentData = numberDocumentSchema.parse(responseData);
        const newAuthRxNo = incrementAuthRxNumber(numberDocumentData.current);
        transaction.update(authRxNoRef, { current: newAuthRxNo });
        return newAuthRxNo;
      });
    })
      .then((newAuthRxNo) => {
        setAuthRxNo(generateAuthRxNumber(newAuthRxNo));
      })
      .catch((error) => {
        setNumbersError(error);
        logError(error);
        toast.error(
          'Unable to generate an authority script number. If authority is required, please try creating a new script.',
        );
      })
      .finally(() => {
        setIsLoadingNumbers(false);
      });

    return Promise.all([scriptTransaction, authTransaction]);
  }, []);

  // Reference the documents using destructuring in any component
  return { scriptNo, authRxNo, isLoadingNumbers, numbersError, fetchNumbers };
};
