import AddressAutocomplete from '../AddressAutocomplete/AddressAutocomplete';
import { StyledPracticeForm } from './PracticeForm.styled';
import { Address, PracticeFormValues } from '../../types/firestore';
import { Button, Checkbox } from '@mantine/core';
import { useForm } from '@mantine/form';
import { handleCheckboxEnterPress, validateRequiredInput } from '../../utils/formUtils';
import { useCallback } from 'react';
import classes from './PracticeForm.module.css';
import { TextInputWithCheckmark } from '../utils/TextInputWithCheckmark';

type PracticeFormProps = {
  initialFormValues?: PracticeFormValues;
  pending: boolean;
  googleLoaded: boolean;
  submitBtnLabel: string;
  handleSubmit: (values: PracticeFormValues) => void;
  handleCancel: () => void;
};

const defaultFormValues = {
  prefix: false,
  practiceName: '',
  streetAddress: '',
  subpremise: '',
  suburb: '',
  postcode: '',
  state: '',
  phoneNumber: '',
};

const PracticeForm = ({
  initialFormValues = defaultFormValues,
  googleLoaded,
  handleSubmit,
  handleCancel,
  submitBtnLabel,
  pending,
}: PracticeFormProps) => {
  const form = useForm<PracticeFormValues>({
    initialValues: {
      ...initialFormValues,
    },

    validate: {
      phoneNumber: (value) =>
        !/^((0[2-8]\d{8})|(13(00|\d{4})(\d{6})?))$/.test(value.trim())
          ? 'A valid Australian phone number is required'
          : null,
      streetAddress: (value) => validateRequiredInput(value),
      suburb: (value) => validateRequiredInput(value),
      postcode: (value) => validateRequiredInput(value),
      state: (value) => validateRequiredInput(value),
    },
    validateInputOnBlur: true,
  });

  // Destructure properties from `form` for use in callbacks. Passing `form` directly leads to
  // infinite re-renders
  const { setValues, getInputProps, onSubmit } = form;

  const handleAddressChange = useCallback(
    (address: Address) => {
      setValues({
        streetAddress: address.streetAddress,
        suburb: address.suburb,
        subpremise: address.subpremise,
        state: address.state,
        postcode: address.postcode,
      });
    },
    [setValues],
  );

  return (
    // Legal requirements include the prescriber's name, address, contact details, and prescriber number
    <StyledPracticeForm
      className="PracticeForm"
      autoComplete="off"
      noValidate
      onSubmit={onSubmit((values, event) => {
        event?.preventDefault();
        handleSubmit(values);
      })}
    >
      <div className="fields">
        <TextInputWithCheckmark
          label="Practice name"
          {...form.getInputProps('practiceName')}
          classNames={{ root: classes.formInput }}
          isValid={form.values.practiceName.trim() !== ''}
        />
        <AddressAutocomplete<PracticeFormValues>
          googleLoaded={googleLoaded}
          form={form}
          handleAddressChange={handleAddressChange}
        />
        <TextInputWithCheckmark
          label="Practice phone number"
          {...form.getInputProps('phoneNumber')}
          description="Include area code if applicable, but no spaces"
          inputWrapperOrder={['label', 'input', 'description', 'error']}
          classNames={{ root: classes.formInput }}
          isValid={form.isValid('phoneNumber')}
        />
        <Checkbox
          label="Include 'Dr' when prescribing medicines from this location"
          onKeyDown={handleCheckboxEnterPress}
          {...getInputProps('prefix', { type: 'checkbox' })}
          classNames={{ root: classes.checkboxInput }}
        />
      </div>
      <div className="PracticeForm__btns">
        <Button className="submit" type="submit" variant="primary" loading={pending}>
          {submitBtnLabel}
        </Button>

        <Button
          variant="grey"
          onClick={(event) => {
            event.preventDefault();
            handleCancel();
          }}
        >
          Cancel
        </Button>
      </div>
    </StyledPracticeForm>
  );
};

export default PracticeForm;
