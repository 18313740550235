import { StyledDashboard } from './Dashboard.styled';
import { Link } from 'react-router-dom';
import Favourites from '../Favourites/Favourites';
import type { User } from 'firebase/auth';
import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import { Helmet } from 'react-helmet-async';
import { Button, LoadingOverlay } from '@mantine/core';
import { VerifyEmailModal } from './VerifyEmailModal';
import { useMedications } from '../../context/MedicationsContext';
import { useAuthContext } from '../../hooks/useAuthContext';

type DashboardProps = {
  user: User;
};

const Dashboard = ({ user }: DashboardProps) => {
  const { isVerificationSent, setIsVerificationSent } = useAuthContext();
  const { isLoadingMedications } = useMedications();

  return (
    <>
      <Helmet>
        <title>Dashboard · OptomRx</title>
        <meta
          name="description"
          content="Choose a favourite script to quickly write a prescription, or start fresh with a blank prescription."
        />
        <link rel="canonical" href="/dashboard" />
      </Helmet>
      <LoadingOverlay visible={isLoadingMedications} overlayProps={{ blur: 2 }} />
      <ContentContainer earlyPadding={true}>
        <StyledDashboard className="Dashboard">
          <PageHeader
            title={`Welcome${user.displayName ? `, ${user.displayName}` : ''}`}
            description="Create a new script or prescribe one of your favourites"
          />

          <Button component={Link} to="/prescription/new" variant="primary" size="md">
            New prescription
          </Button>

          <div className="Favourites">
            <Favourites user={user} />
          </div>
          <VerifyEmailModal
            isOpen={isVerificationSent}
            onClose={() => setIsVerificationSent(false)}
          />
        </StyledDashboard>
      </ContentContainer>
    </>
  );
};

export default Dashboard;
