import { StyledPractices } from './Practices.styled';
import { Link } from 'react-router-dom';
import PracticesTable from './PracticesTable';
import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import { Helmet } from 'react-helmet-async';
import type { User } from 'firebase/auth';
import { PracticeDocumentWithId } from '../../types/firestore';
import { useHandleFetchError } from '../../hooks/useHandleFetchError';
import { Button, Loader } from '@mantine/core';
import { usePractices } from '../../hooks/usePractices';

type PracticesProps = {
  user: User;
};

export type EnrichedPractice = PracticeDocumentWithId & {
  fullName: string | null;
  qualifications: string | null;
  prescriberNumber: string | null;
};

export function Practices({ user }: PracticesProps) {
  const { practices, isLoadingPractices, practicesError } = usePractices(user.uid);

  useHandleFetchError(practicesError);

  const getMainContent = () => {
    if (isLoadingPractices) {
      return <Loader className="loader" />;
    }

    if (practices && practices.length > 0) {
      return <PracticesTable data={practices} rowsPerPage={10} />;
    }

    return (
      <table className="table table-none">
        <thead className="tableRowHeader">
          <tr>
            <th className="tableHeader">Name</th>
            <th className="tableHeader">Location</th>
            <th className="tableHeader actions-header">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr className="tableRowItems">
            <td data-title="Practices" className="tableCellNone" colSpan={3}>
              No practices added yet
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <>
      <Helmet>
        <title>Practices · OptomRx</title>
        <meta
          name="description"
          content="Add, edit, or delete practice details. Use different practices as needed when you create new scripts."
        />
        <link rel="canonical" href="/practices" />
      </Helmet>
      <ContentContainer earlyPadding={true}>
        <StyledPractices className="Practices">
          <PageHeader
            title="Practices"
            description="Add and modify practice details for your prescriptions"
          />
          <Button component={Link} variant="primary" to="/add-practice">
            Add new practice
          </Button>
          <div className="Practices__container">{getMainContent()}</div>
        </StyledPractices>
      </ContentContainer>
    </>
  );
}
