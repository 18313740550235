import { Button, Select } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { PracticeDocumentWithId, PrescriptionFormValues } from '../../types/firestore';
import { UseFormReturnType } from '@mantine/form';
import classes from './PracticeDetails.module.css';
import { useHandleFetchError } from '../../hooks/useHandleFetchError';
import { usePractices } from '../../hooks/usePractices';
import { TextInputSkeleton } from '../utils/Skeletons/TextInputSkeleton';

type PracticeDetailsProps = {
  form: UseFormReturnType<PrescriptionFormValues>;
  userId: string;
};

type SelectOption = {
  label: string;
  value: string;
  isDefault?: boolean;
};

// Created to improve parent RxForm readability only
export const PracticeDetails = ({ form, userId }: PracticeDetailsProps) => {
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);
  const [chosenPractice, setChosenPractice] = useState<SelectOption | null>(null);

  const { practices, isLoadingPractices, practicesError } = usePractices(userId);
  useHandleFetchError(practicesError);

  const { setFieldValue } = form;

  // Have to set fields individually as there's no type support for nested properties when using
  // `setValues`
  const handleUpdatePracticeFormValues = useCallback(
    (practiceDetails: PracticeDocumentWithId) => {
      setFieldValue('practiceData.default', practiceDetails.default);
      setFieldValue('practiceData.phoneNumber', practiceDetails.phoneNumber);
      setFieldValue('practiceData.practiceName', practiceDetails.practiceName);
      setFieldValue('practiceData.prefix', practiceDetails.prefix);
      setFieldValue('practiceData.streetAddress', practiceDetails.streetAddress);
      setFieldValue('practiceData.suburb', practiceDetails.suburb);
      setFieldValue('practiceData.subpremise', practiceDetails.subpremise);
      setFieldValue('practiceData.state', practiceDetails.state);
      setFieldValue('practiceData.postcode', practiceDetails.postcode);
    },
    [setFieldValue],
  );

  // Used to fill the React Select component options using practices fetched from firestore. Will also set the selected option to the default practice if one exists
  useEffect(() => {
    // Do not run unless a practices collection exists (i.e. has been fetched from firebase)
    if (practices) {
      const practiceSelectOptions: SelectOption[] = [];

      practices.forEach((practice, index) => {
        const option: SelectOption = {
          value: practice.id,
          label:
            practice.practiceName !== ''
              ? `${practice.practiceName}, ${practice.suburb}`
              : `Unnamed practice, ${practice.suburb}`,
        };

        // Add the practice to the select option list
        practiceSelectOptions.push(option);

        // Set the first practice as selected. If a default exists, this will be replaced
        if (index === 0 || practice.default) {
          setChosenPractice(option);
          // Also set state to practice data to ensure the form is pre-filled. Do NOT use previous data. Overwrite.
          handleUpdatePracticeFormValues(practice);
        }
      });
      setSelectOptions(practiceSelectOptions);
    }
  }, [practices, handleUpdatePracticeFormValues]);

  // A handle change function specifically for the select element. \
  // Sets both the input state and practiceData based on selection
  const handleSelectChange = (selectedValue: string | null) => {
    if (selectedValue === null) {
      // Handle the case when no option is selected
      setChosenPractice(null);
      return;
    } else {
      const practice = practices?.find((p) => p.id === selectedValue);
      if (practice) {
        setChosenPractice({ label: practice.practiceName, value: practice.id });
        handleUpdatePracticeFormValues(practice);
      }
    }
  };

  if (isLoadingPractices || !practices) {
    return <TextInputSkeleton />;
  }

  return (
    <>
      {practices.length > 0 ? (
        <Select
          data={selectOptions}
          value={chosenPractice ? chosenPractice.value : null}
          onChange={handleSelectChange}
          placeholder="Select practice..."
          label="Select practice"
          allowDeselect={false}
          size="md"
          classNames={{
            root: classes.selectInput,
            input: classes.selectWrapper,
            label: classes.selectLabel,
          }}
        />
      ) : (
        <Button
          className="add-new-btn"
          component={Link}
          to="/add-practice"
          variant="primary"
          size="md"
        >
          Add new practice
        </Button>
      )}
    </>
  );
};
