// MedicationsContext.js
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useCollection } from '../hooks/useCollection';
import { Medication, medicationSchema } from '../types/medication';
import { useHandleFetchError } from '../hooks/useHandleFetchError';
import { MEDICATIONS_COLLECTION } from '../entities/collections';
import { useAuthContext } from '../hooks/useAuthContext';

type MedicationContext = {
  medications: Medication[] | null;
  isLoadingMedications: boolean;
  medicationsError: unknown;
};

/**
 * A context for managing the medications collection from Firestore.
 *
 * In development mode, hardcoded medications are used instead of fetching from Firestore.
 * This avoids unnecessary Firestore reads during development, which can stack very quickly.
 */
const MedicationsContext = createContext<MedicationContext | null>(null);

export const MedicationsProvider = ({ children }: { children: ReactNode }) => {
  const { authIsReady, user } = useAuthContext();
  const isQueryEnabled = authIsReady && user !== null;
  const {
    data: medications,
    isLoading,
    error,
  } = useCollection(MEDICATIONS_COLLECTION, medicationSchema, {
    isEnabled: isQueryEnabled,
  });

  // Prevent context consumers from re-rendering unless any of the actual values change
  const value = useMemo(() => {
    return {
      medications,
      isLoadingMedications: isLoading,
      medicationsError: error,
    } satisfies MedicationContext;
  }, [medications, isLoading, error]);

  useHandleFetchError(value.medicationsError);

  return <MedicationsContext.Provider value={value}>{children}</MedicationsContext.Provider>;
};

export const useMedications = () => {
  const context = useContext(MedicationsContext);
  if (!context) {
    throw new Error('`useMedications` must be used within a `MedicationsProvider`');
  }
  return context;
};
