import { useEffect, useRef, useCallback, ChangeEvent, useState } from 'react';
import { StyledDrugAutocomplete } from './DrugAutocomplete.styled';
import { PrescriptionFormValues } from '../../types/firestore';
import { AlertState, SetAlertFunc } from '../utils/Alert/Alert';
import { z } from 'zod';
import { UseFormReturnType } from '@mantine/form';
import { Checkbox, HoverCard, Group, Tooltip } from '@mantine/core';
import { handleCheckboxEnterPress } from '../../utils/formUtils';
import classes from '../RxForm/RxForm.module.css';
import { TextInputWithCheckmark } from '../utils/TextInputWithCheckmark';
import { IconHelp } from '@tabler/icons-react';
import drugAutocompleteClasses from './DrugAutocomplete.module.css';
import { Medication } from '../../types/medication';
import { checkIs60DayMedicine } from '../RxTemplate/RxTemplate';

const drugItemDatasetSchema = z.object({
  id: z.string(),
});

type DrugAutocompleteProps = {
  alerts: AlertState;
  setAlerts: SetAlertFunc;
  form: UseFormReturnType<PrescriptionFormValues>;
  /**
   * List of medications to display in the autocomplete list.
   */
  medications: Medication[];
  /**
   * Callback function to handle when a medication is selected from the autocomplete list.
   */
  onSelectMedication: (medication: Medication) => void;
  lemiComponent: React.ReactNode;
};

/**
 * Determines the width of a text string in the given font + size.
 *
 * @param text - The text string to measure.
 * @param font - The font style to use when measuring the text. Defaults to '1rem Segoe UI'.
 * @returns The approximate width of the text in pixels.
 */
function getTextWidth(text: string, font: string = '1rem Segoe UI'): number {
  // Create a temporary canvas element to measure the text
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) {
    throw new Error('Canvas context could not be created.');
  }

  context.font = font;
  return context.measureText(text).width;
}

const DrugAutocomplete = ({
  alerts,
  setAlerts,
  form,
  medications,
  onSelectMedication,
  lemiComponent,
}: DrugAutocompleteProps) => {
  // useRef allows us to store the equivalent of a 'global' component variable without losing data on re-render, but avoiding the async problems that can arise with state
  const currentFocus = useRef(-1);
  const itemsListRef = useRef<HTMLDivElement>(null);

  const { getInputProps, setFieldValue } = form;

  const [isActiveIngredientTooltipVisible, setIsActiveIngredientTooltipVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  /**
   * Checks whether the provided text overflows the autocomplete input field.
   *
   * @param selectedValue - The string value to check for overflow.
   * @returns True if the text overflows the input field, false otherwise.
   */
  const checkHasOverflown = useCallback((selectedValue: string) => {
    const inputElement = inputRef.current;
    if (inputElement) {
      const paddingBufferPx = 54;
      const textWidth = getTextWidth(selectedValue);
      return textWidth + paddingBufferPx > inputElement.clientWidth;
    }
    return false;
  }, []);

  const showSuccessClass = (element: HTMLElement) => {
    element.classList.remove('error');
    element.classList.add('success');
  };

  // Hide the items list but don't alter the items on the list
  const hideItemsList = () => {
    // Check for null in cases where component is dismounting/ed
    if (document.querySelector('.items-list')) {
      document.querySelector('.items-list')?.classList.add('hide');
      document.querySelector('.items-list')?.classList.remove('show-list');
    }
  };

  // Show the items list but don't alter the items on the list
  const showItemsList = () => {
    document.querySelector('.items-list')?.classList.remove('hide');
    document.querySelector('.items-list')?.classList.add('show-list');
  };

  // Remove all items within the list, rather than the list itself
  const removeList = () => {
    // Must reset focus here to avoid starting halfway down a list on first arrow key press
    currentFocus.current = -1;
    document.querySelectorAll('.item').forEach((item) => {
      item.remove();
    });
  };

  // Capture the selection made in the items list via event delegation
  // All child spans have pointer events set to none, so the parent item element will ALWAYS capture the even here
  const clickSuggestion = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const dataset = drugItemDatasetSchema.parse(target.dataset);
      const medication = medications.find((med) => med.id === dataset.id);
      if (!medication) {
        console.error(`Medication ${dataset.id} not found in list`);
        return;
      }

      const { medicinalProductPackName } = medication;

      // Updates all relevant form values on suggestion click
      onSelectMedication(medication);

      removeList();

      // Remove errors
      showSuccessClass(document.querySelector('#activeIngredient') as HTMLInputElement);
      setAlerts((prevAlerts) => ({
        ...prevAlerts,
        activeIngredient: null,
        brandName: null,
      }));

      // Check for overflow (i.e. large drug name) so a tooltip is enabled if necessary
      if (checkHasOverflown(medicinalProductPackName)) {
        setIsActiveIngredientTooltipVisible(true);
      }

      // Finally, set focus to next type-able field (currently dosage)
      const dosageInput = document.querySelector('#dosage') as HTMLInputElement;
      dosageInput.focus();
    },
    [setAlerts, checkHasOverflown, medications, onSelectMedication],
  );

  // Given a string, use the current search text and regex to bold the segment of text being searched for (using HTML)
  const boldLetters = (string: string) => {
    const inputElement: HTMLInputElement | null = document.querySelector('#activeIngredient');
    const currentSearchTerm = inputElement?.value.trim() || '';

    if (!currentSearchTerm) {
      return string;
    }

    // Escape special regex characters
    const escapedSearchText = currentSearchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Define regex patterns based on updated search logic
    const regexFirst = new RegExp(`^(${escapedSearchText})`, 'i'); // Match at start
    const regexSecond = new RegExp(`\\+\\s*(${escapedSearchText})`, 'i'); // Match after a '+'
    const regexContains = new RegExp(`(${escapedSearchText})`, 'i'); // General substring match

    // Prioritize matches: First -> Second -> General Contains
    if (regexFirst.test(string)) {
      return string.replace(regexFirst, `<strong class="item-bold item-click">$1</strong>`);
    } else if (regexSecond.test(string)) {
      return string.replace(regexSecond, `+ <strong class="item-bold item-click">$1</strong>`);
    } else if (regexContains.test(string)) {
      return string.replace(regexContains, `<strong class="item-bold item-click">$1</strong>`);
    }

    return string; // No matches, return original
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value.trim();

    if (!searchText) {
      createList([]);
      return;
    }

    // Escape regex special characters
    const escapedSearchText = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    let regexFirst: RegExp | null = null;
    let regexSecond: RegExp | null = null;
    let regexContains: RegExp | null = null; // New regex to allow general substring matches

    try {
      regexFirst = new RegExp(`^${escapedSearchText}`, 'i'); // Starts with searchText
      regexSecond = new RegExp(`\\+.*${escapedSearchText}`, 'i'); // Contains searchText after a '+'
      regexContains = new RegExp(`${escapedSearchText}`, 'i'); // General substring match
    } catch (error) {
      createList([]);
      return;
    }

    const firstMatches = new Set();

    const matches = medications
      .filter((medication) => {
        const { medicinalProductPackName, brandName } = medication;

        const primaryMatch =
          regexFirst.test(medicinalProductPackName) || (brandName && regexFirst.test(brandName));

        if (primaryMatch) {
          firstMatches.add(medication);
          return true;
        }

        const secondaryMatch =
          regexSecond.test(medicinalProductPackName) || (brandName && regexSecond.test(brandName));

        const generalMatch =
          regexContains.test(medicinalProductPackName) ||
          (brandName && regexContains.test(brandName));

        return secondaryMatch || generalMatch;
      })
      .sort((a, b) => {
        // Sort by brand name, if available, otherwise leave default order
        if (a.brandName && b.brandName) {
          return a.brandName.localeCompare(b.brandName);
        }
        return 0;
      });

    createList(matches);
  };

  // Creates list of autocomplete items using an array of relevant suggestions (matchArr)
  const createList = useCallback((matchArr: Medication[]) => {
    // First remove any lists present to ensure the list if refreshed on each new input
    removeList();
    // Also ensure the list does not have a hide class active
    showItemsList();
    const itemsList = document.querySelector('.items-list');

    // Limit the autocomplete list to a specified amount of items
    const maxListItems = Infinity;

    for (let i = 0; i < maxListItems; i++) {
      // Don't attempt to iterate beyond the number of matches returned, which may be less than maxListItems
      if (i >= matchArr.length) {
        break;
      }

      const match = matchArr[i];
      const is60DayMedicine = checkIs60DayMedicine(match);

      // Operation in template literal is capitalising first letter
      const boldActiveName = boldLetters(
        `${
          match.medicinalProductPackName[0].toUpperCase() +
          match.medicinalProductPackName.substring(1)
        }`,
      );
      const boldBrandName = boldLetters(`${match.brandName}`);

      const item = document.createElement('div');
      // Using spans will allow alternate styling of active ingredient and brand name if desired
      item.innerHTML = `<span class="item-active item-click">${boldActiveName}</span> <span class="item-brand item-click">${
        boldBrandName ? `(${boldBrandName})` : ''
      }${is60DayMedicine ? '<span class="sixty-day-chip">60 Day</span>' : ''}</span>`;
      item.dataset.id = match.id;
      item.classList.add('item');
      item.classList.add('item-click');
      itemsList?.appendChild(item);
    }
  }, []);

  // Make absolutely sure any dependency functions in this hook are wrapped in useCallback
  useEffect(() => {
    const input = document.querySelector('#activeIngredient') as HTMLInputElement;

    // add item-click class to disable hiding of items list on outside click
    input.classList.add('item-click');

    // Check if the itemsList already has event listeners attached
    const currentItemsList = itemsListRef.current;
    if (currentItemsList) {
      currentItemsList.addEventListener('click', clickSuggestion);
      document.querySelector('.activeIngredient')?.appendChild(currentItemsList);
    }

    // Removes the active class from all autocomplete items
    const removeActive = (itemsArr: NodeListOf<HTMLElement>) => {
      itemsArr.forEach((item) => {
        item.classList.remove('active');
      });
    };

    // Add the active class to a specified item in the autocomplete list
    const addActive = (itemsArr: NodeListOf<HTMLElement>) => {
      // First remove any active classes
      removeActive(itemsArr);
      // If the user has pressed down more than the current length of the autocomplete list, or presses down on the last item, cycle back to the top of the list
      if (currentFocus.current >= itemsArr.length) {
        currentFocus.current = 0;
      }
      // Similarly, if the user presses up too many times, cycle to the bottom of the list
      if (currentFocus.current < 0) {
        currentFocus.current = itemsArr.length - 1;
      }

      const activeItem = itemsArr[currentFocus.current];
      activeItem.classList.add('active');

      // Ensure the active item is visible
      activeItem.scrollIntoView({ block: 'nearest', behavior: 'instant' });
    };

    // The currentFocus variable will be used as an index when adding an active class to an item in the itemsList list
    const keyItemNav = (e: KeyboardEvent) => {
      // This is the array of list items that will be moved through using the currentFocus variable
      const items = document.querySelectorAll('.item') as NodeListOf<HTMLElement>;
      if (items.length > 0) {
        if (e.key === 'ArrowDown') {
          currentFocus.current++;
          /*and and make the current item more visible:*/
          addActive(items);
        } else if (e.key === 'ArrowUp') {
          //up
          // Decrease the currentFocus variable when the DOWN key is pressed
          currentFocus.current--;
          /*and and make the current item more visible:*/
          addActive(items);
        } else if (e.key === 'Enter') {
          // Ensure the form isn't submitted when simply selecting an option
          e.preventDefault();
          if (currentFocus.current > -1) {
            // Simulated a click on the currently 'focused' item
            items[currentFocus.current].click();
          }
        }
      }
    };

    // Ensure the items list closes on outside click
    const itemsListOutsideClick = (e: MouseEvent) => {
      // All items within the autocomplete input and items list will contain this class as a marker of sorts
      const eventTarget = e.target as HTMLElement;
      if (!eventTarget.classList.contains('item-click')) {
        hideItemsList();
      }
    };

    // Check for non-whitespace character to indicate a valid value to create an autocomplete list from. Note this will only occur if the user performs tab out of the input, or an outside click
    const checkForListCreate = () => {
      if (input.value.trim().length > 0) {
        showItemsList();
      }
    };

    // Listen for tab out specifically, and hide the itemsList in response
    const tabOut = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        hideItemsList();
      }
    };

    input.addEventListener('focus', checkForListCreate);
    input.addEventListener('keydown', keyItemNav);
    input.addEventListener('keydown', tabOut);
    window.addEventListener('click', itemsListOutsideClick);

    return () => {
      // Remove event listeners on dismount
      input.removeEventListener('focus', checkForListCreate);
      input.removeEventListener('keydown', keyItemNav);
      input.removeEventListener('keydown', tabOut);
      window.removeEventListener('click', itemsListOutsideClick);
      currentItemsList?.removeEventListener('click', clickSuggestion);
    };
  }, [clickSuggestion, createList]);

  return (
    <StyledDrugAutocomplete className="DrugAutocomplete expanded">
      <Tooltip
        label={form.values.drugData.activeIngredient}
        disabled={!isActiveIngredientTooltipVisible}
      >
        <TextInputWithCheckmark
          id="activeIngredient"
          ref={inputRef}
          classNames={{ root: classes.drugAutocompleteInput }}
          label="Active ingredient"
          placeholder="Enter active ingredient or brand name"
          {...form.getInputProps('drugData.activeIngredient')}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            // This in essence marks existing data as unverified or stale
            setFieldValue('medication', null);
            setIsActiveIngredientTooltipVisible(false);
            handleSearch(event);
            form.getInputProps('drugData.activeIngredient').onChange(event);
          }}
          description={alerts.activeIngredient?.message}
          inputWrapperOrder={['label', 'input', 'description', 'error']}
          isValid={form.isValid('drugData.activeIngredient')}
        />
      </Tooltip>
      <div ref={itemsListRef} className="items-list item-click">
        {/* items will be dynamically added here */}
      </div>
      <div className="drug-collapse show">
        <TextInputWithCheckmark
          id="brandName"
          classNames={{ root: classes.formInput }}
          label="Brand name"
          {...form.getInputProps('drugData.brandName')}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            // PBS data is specific to brand name, so this must reset the medication.
            // Potentially we could check that it's a PBS medication first? For non-PBS medications,
            // it may not be necessary.
            setFieldValue('medication', null);
            getInputProps('drugData.brandName').onChange(event);
          }}
          description={alerts.brandName?.message}
          inputWrapperOrder={['label', 'input', 'description', 'error']}
          // Only show checkmark if the form value is valid and 'include brand' is selected,
          // This prevents an empty brand name field from showing a checkmark
          isValid={form.isValid('drugData.brandName') && form.values.drugData.includeBrand}
        />

        <Checkbox
          classNames={{ root: `${classes.checkboxInput}` }}
          label="Include brand name on prescription"
          onKeyDown={handleCheckboxEnterPress}
          {...getInputProps('drugData.includeBrand', { type: 'checkbox' })}
        />

        <div className="brandOnly-container">
          <Checkbox
            classNames={{ root: `${classes.checkboxInput}` }}
            label="Prescribe by brand name only"
            onKeyDown={handleCheckboxEnterPress}
            {...getInputProps('drugData.brandOnly', { type: 'checkbox' })}
          />
          {lemiComponent ? (
            <Group justify="center">
              <HoverCard
                width={260}
                shadow="md"
                position="right"
                withArrow
                classNames={{
                  dropdown: drugAutocompleteClasses.dropdown,
                }}
              >
                <HoverCard.Target>
                  <IconHelp
                    aria-label="Show more information on brand name vs active ingredient prescribing for this medication"
                    width={22}
                    className={classes.helpIcon}
                  />
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <p>{lemiComponent}</p>
                </HoverCard.Dropdown>
              </HoverCard>
            </Group>
          ) : null}
        </div>

        <Checkbox
          classNames={{ root: `${classes.checkboxInput}` }}
          label="Brand substitution permitted"
          onKeyDown={handleCheckboxEnterPress}
          {...getInputProps('drugData.substitutePermitted', { type: 'checkbox' })}
        />

        <Checkbox
          classNames={{ root: `${classes.checkboxInput}` }}
          label="To be compounded"
          onKeyDown={handleCheckboxEnterPress}
          {...getInputProps('drugData.compounded', { type: 'checkbox' })}
        />
      </div>
    </StyledDrugAutocomplete>
  );
};

export default DrugAutocomplete;
