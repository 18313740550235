import { Button, Modal, Alert, PasswordInput } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import classes from './DeleteAccountModal.module.css';
import baseModalClasses from '../../styles/Modal.module.css';
import { useForm } from '@mantine/form';
import { FirebaseError } from 'firebase/app';
import { deleteUser } from 'firebase/auth';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { db } from '../../firebase/config';
import { useErrorHandling } from '../../hooks/useErrorHandling';
import { validateRequiredInput } from '../../utils/formUtils';
import { logError } from '../../utils/logError';
import type { User } from 'firebase/auth';
import { refreshCredentials } from './Account';
import {
  PRACTICES_COLLECTION,
  PRESCRIPTIONS_COLLECTION,
  USERS_COLLECTION,
} from '../../entities/collections';
import { logout } from '../../utils/logout';

type DeleteAccountModalProps = {
  isOpen: boolean;
  onClose: () => void;
  user: User;
};

type FormValues = {
  password: string;
};

export function deleteAccount({
  user,
  shouldDeleteAuthUser,
}: {
  user: User;
  shouldDeleteAuthUser: true;
}): Promise<void>;
export function deleteAccount({
  userId,
  shouldDeleteAuthUser,
}: {
  userId: string;
  shouldDeleteAuthUser: false;
}): Promise<void>;

/**
 * Completely deletes the user's account, including all practices, scripts, and related data.
 */
export async function deleteAccount({
  user,
  userId,
  shouldDeleteAuthUser,
}: {
  user?: User;
  userId?: string;
  shouldDeleteAuthUser: boolean;
}) {
  const uid = shouldDeleteAuthUser ? user?.uid : userId;
  if (!uid) {
    throw new Error('User ID is required.');
  }
  // Gather a reference to all of the user's practices
  const practicesRef = collection(db, PRACTICES_COLLECTION);
  const practicesQuery = query(practicesRef, where('userId', '==', uid));
  const practicesSnap = await getDocs(practicesQuery);

  // Gather a reference to all of the user's prescriptions
  const prescriptionsRef = collection(db, PRESCRIPTIONS_COLLECTION);
  const prescriptionsQuery = query(prescriptionsRef, where('userDocumentId', '==', uid));
  const prescriptionsSnap = await getDocs(prescriptionsQuery);

  try {
    // Delete all of the user's practices
    await Promise.all(practicesSnap.docs.map((doc) => deleteDoc(doc.ref)));

    // Delete all of the user's prescriptions
    await Promise.all(prescriptionsSnap.docs.map((doc) => deleteDoc(doc.ref)));

    // Delete the user's document in firestore
    await deleteDoc(doc(db, USERS_COLLECTION, uid));

    // Conditionally delete the user from Firebase Authentication
    if (shouldDeleteAuthUser && user) {
      await deleteUser(user);
      toast.success('Account and all related data deleted.');
    } else {
      toast.success('All user-related data deleted, but account remains.');
    }
    toast.success('Account deleted');
  } catch (error) {
    toast.error('An error occurred while deleting your account. Please try again.');
    logError(error);
  }
}

/**
 * Modal to confirm deletion of a favourite.
 */
export const DeleteAccountModal = ({ isOpen, onClose, user }: DeleteAccountModalProps) => {
  const { handleSettingsError } = useErrorHandling();

  const [deletePending, setDeletePending] = useState(false);
  const [globalError, setGlobalError] = useState<string | null>(null);

  const form = useForm<FormValues>({
    initialValues: {
      password: '',
    },

    validate: {
      password: (value) => validateRequiredInput(value, 'Please enter a password'),
    },
  });

  // Combine all actions into a single function that handles credentials and delete operations
  const performDeleteAccount = async (password: string) => {
    setDeletePending(true);
    let reauthenticated = false;

    // First refresh credentials (even if the user recently signed in)
    try {
      await refreshCredentials(user, password);
      reauthenticated = true;
    } catch (error) {
      if (error instanceof FirebaseError) {
        setGlobalError(handleSettingsError(error.code));
      } else {
        logError(error);
      }
      setDeletePending(false);
    }
    // Proceed with operations if the user provides correct sign in details;
    if (reauthenticated) {
      await deleteAccount({
        user,
        shouldDeleteAuthUser: true,
      });
      setDeletePending(false);
      logout();
    }
    setDeletePending(false);
  };

  return (
    <Modal title="Delete practice" onClose={onClose} opened={isOpen}>
      <Alert variant="light" icon={<IconInfoCircle />} color="red" title="Attention">
        This action is permanent and cannot be undone
      </Alert>
      <div className={classes.selectedAccountContainer}>
        <div className={classes.selectedAccountLabel}>Please enter your password to continue</div>
      </div>
      <form
        noValidate
        onSubmit={form.onSubmit(async (values, event) => {
          event?.preventDefault();
          await performDeleteAccount(values.password);
        })}
      >
        <PasswordInput
          className={classes.formInput}
          label="Password"
          autoComplete="password"
          {...form.getInputProps('password')}
          onChange={(event) => {
            form.getInputProps('password').onChange(event);
            setGlobalError('');
          }}
        />

        {globalError ? (
          <Alert
            variant="light"
            icon={<IconInfoCircle />}
            color="red"
            className={classes.errorAlert}
          >
            {globalError}
          </Alert>
        ) : null}

        <div className={baseModalClasses.buttons}>
          <Button
            className="cancel"
            variant="grey"
            onClick={() => {
              form.reset();
              setGlobalError(null);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button variant="danger" type="submit" loading={deletePending}>
            Delete
          </Button>
        </div>
      </form>
    </Modal>
  );
};
