import { z } from 'zod';
import { Medication } from './medication';
import { pbsRestrictionSchema } from './pbsApiEntities';
import { Timestamp } from 'firebase/firestore';

export type DrugData = {
  activeIngredient: string;
  brandName: string;
  quantity: string;
  repeats: string;
  dosage: string;
  /**
   * Indicates if brand substitution is permitted
   */
  substitutePermitted: boolean;
  /**
   * Indicates whether the Rx should list brand name only (only permitted for certain drugs)
   */
  brandOnly: boolean;
  /**
   * Indicates whether brand name should be included on the Rx
   */
  includeBrand: boolean;
  /**
   * Indicates whether this is a PBS prescription
   */
  pbsRx: boolean;
  /**
   * Whether the prescription requires compounding
   */
  compounded: boolean;
  /**
   * Set to true when the user selects an autocomplete option. Set to false on any subsequent modification of drug information, as this cannot be verified on the PBS. Only those verified drugs should be integrated with PBS backend
   */
  verified: boolean;
  /**
   * Indications for the use of drug under PBS restriction
   */
  indications: string;
  authRequired: boolean;
  /**
   * The ID of the selected medication. A value of 'null' indicates that the user has not selected
   * a medication from the database (might've entered a custom medication).
   */
  medicationId: string | null;
};

export type PatientData = {
  fullName: string;
  medicareNumber: string;
  medicareRefNumber: string;
} & Address;

// FIXME: move all the user-specific stuff into prescriber or user document please!
// Keep this strictly practice details. User ID probably necessary to link back to user, but ideally
// we move all a user's practices into a practices array on the User document
export type PracticeData = {
  default: boolean;
  fullName: string;
  phoneNumber: string;
  practiceName: string;
  prefix: boolean;
  prescriberNumber: string;
  qualifications: string;
  userId: string;
} & Address;

export type MiscData = {
  /**
   * The date the Rx was written (can be manually modified)
   */
  date: string;
  /**
   * Auto-generated
   */
  authRxNumber: string;
  /**
   * Either a streamline authority code, or a code obtained via telephone or online approval
   */
  authCode: string;
  /**
   * Unique identifier for every prescription
   */
  scriptID: string;
  /**
   * The reason why the drug was prescribed (authority scripts only)
   */
  justification: string;
  /**
   * Whether the patient has received prior authority for this medication (authority scripts only)
   */
  prevAuth: boolean;
  /**
   * Age of the patient (authority scripts only)
   */
  age: string;
};

export const pbsDataSchema = z.object({
  'program-code': z.string(),
  'atc-level-code': z.string(),
  'atc-type': z.string(),
  'atc-print-option': z.string(),
  'item-code': z.string(),
  'restriction-flag': z.string(),
  'has-caution': z.string(),
  'has-note': z.string(),
  mq: z.string(),
  repeats: z.string(),
  'manufacturer-code': z.string(),
  'pack-size': z.string(),
  'markup-band': z.string(),
  'fee-code': z.string(),
  'dangerous-drug-code': z.string(),
  'brand-premium': z.string(),
  'therapeutic-premium': z.string(),
  cp2p: z.string(),
  cdpmq: z.string(),
  lp2p: z.string(),
  ldpmq: z.string(),
  mp2p: z.string(),
  mdpmq: z.string(),
  mrvsn: z.string(),
  bioequivalence: z.string(),
  'brand-name': z.array(z.string()),
  'mp-pt': z.string(),
  'tpuu-or-mpp-pt': z.string(),
  'indication-id': z.string(),
  'increase-code': z.string(),
  'note-ids': z.array(z.string()),
  caution_ids: z.array(z.string()),
  indications: z
    .object({
      description: z.string(),
      'misc-res-code': z.string(),
      'date-req': z.string(),
      'text-req': z.string(),
    })
    .partial(),
  notes: z.array(z.string()),
  cautions: z.array(z.string()),
  'streamline-code': z.string(),
  atc: z.string(),
  'caution-ids': z.array(z.string()),
  lmbc: z.boolean(),
  lemi: z.boolean(),
});

/**
 * JSON data shape for PBS medication entries directly downloaded from the PBS developers page.
 */
export type PbsData = z.infer<typeof pbsDataSchema>;

/**
 * A comprehensive list of all possible route pathnames in the app
 */
export type PathName =
  | '/'
  | '/privacy-policy'
  | '/terms-of-service'
  | '/features'
  | '/faq'
  | '/about'
  | '/dashboard'
  | '/settings'
  | '/prescription/new'
  | '/prescription/review'
  | '/signup'
  | '/login'
  | `/edit-practice/${string | number}`
  | '/add-practice'
  | '/practices'
  | '/scripts'
  | `/scripts/${string | number}`
  | '/reset-password'
  | '/admin';

export const numberDocumentSchema = z.object({
  current: z.string(),
});

export const addressSchema = z.object({
  streetAddress: z.string(),
  subpremise: z.string(),
  suburb: z.string(),
  postcode: z.string(),
  state: z.string(),
});

export const prescriberSchema = z
  .object({
    default: z.boolean(),
    fullName: z.string(),
    phoneNumber: z.string(),
    practiceName: z.string(),
    prefix: z.boolean(),
    prescriberNumber: z.string(),
    qualifications: z.string(),
    uid: z.string(),
  })
  .merge(addressSchema);

export const prescriptionSchema = z
  .object({
    /**
     * The active ingredient of the medication, as provided by the user.
     * In many cases, this is equivalent to 'Medicinal product pack name', however the latter is
     * derived from medication details in the database.
     *
     * NOTE: strictly speaking this is more so 'Medicinal product pack name' because it includes
     * formulation details in many cases.
     */
    activeIngredient: z.string(),
    /**
     * Only provided if the prescription is an authority script
     */
    authorityCode: z.string().nullable(),
    /**
     * A number assigned to every prescription, but only used by dispensers for authority scripts.
     */
    authorityPrescriptionNumber: z.string(),
    /**
     * The brand name of the medication, as provided by the user.
     */
    brandName: z.string(),
    /**
     * The reason why the medication was prescribed, in an authority prescription context.
     */
    clinicalJustification: z.string().nullable(),
    /**
     * The user-provided dosage of the medication.
     */
    dosage: z.string(),
    /**
     * A custom name for the prescription if the user has saved it as a favourite.
     * Not required for favourite prescriptions, but can be provided.
     */
    favouriteName: z.string().nullable(),
    /**
     * Whether the patient has received prior authority for this medication.
     * Only specified for authority scripts. A value of `null` indicates that the user has not
     * specified this information.
     */
    hasPreviouslyReceivedAuthority: z.boolean().nullable(),
    /**
     * Whether the user has selected 'Authority required' for the medication.
     *
     * NOTE: This is not the same as `isAuthorityRequired` in the PBS snapshot. This field is
     * user-provided, while the PBS snapshot is a snapshot of the PBS data at the time of
     * prescribing.
     */
    isAuthoritySelected: z.boolean(),
    /**
     * Whether the brand name should be included on the prescription.
     */
    isBrandNameIncluded: z.boolean(),
    /**
     * Whether the medication should be prescribed using the brand name only.
     */
    isBrandNameOnly: z.boolean(),
    /**
     * Whether brand substitution is permitted for the medication.
     */
    isBrandSubstitutionPermitted: z.boolean(),
    /**
     * Whether the prescription requires compounding.
     */
    isCompoundingRequired: z.boolean(),
    /**
     * Whether the prescription has been saved as a favourite.
     */
    isFavourite: z.boolean(),
    /**
     * Whether the prescription is a PBS prescription.
     */
    isPbsPrescription: z.boolean(),
    /**
     * Whether the prescription is an RPBS prescription.
     */
    isRpbsPrescription: z.boolean(),
    /**
     * The ID of the prescribed medication. Can be used for lookup purposes, though the medication
     * information may have changed since the prescription was written.
     *
     * This field is nullable because users may prescribe medications that are not in the database,
     * and legacy scripts may not have a medication ID. Presence of a medication ID does indicate
     * that the user selected a medication from the database at the time.
     */
    medicationId: z.string().nullable(),
    /**
     * The full medicinal product pack name of the medication.
     * Only provided for medications in our database. Therefore, a value of `null` indicates that
     * the medication is not in the database.
     */
    medicinalProductPackName: z.string().nullable(),
    /**
     * The patient's age, if they are under 18 years old.
     * Only required for authority prescriptions.
     */
    patientAge: z.string().nullable(),
    /**
     * A snapshot of the PBS data for the medication at the time of prescribing.
     *
     * A value of `null` indicates that the pbs details were not available at the time of
     * prescribing. This is usually because the script isn't a PBS script, or it's a legacy script
     * before this information was accurately recorded.
     *
     * Alternatively the user may opt for a PBS script, but the PBS data isn't available for
     * whatever reason (e.g. the website hasn't updated on time).
     */
    pbsSnapshot: z
      .object({
        /**
         * Whether the medication required authority at the time of prescribing.
         */
        isAuthorityRequired: z.boolean(),
        /**
         * The maximum quantity of the medication that could be prescribed.
         */
        maxQuantity: z.number().nullable(),
        /**
         * The maximum number of repeats that could be prescribed.
         */
        maxRepeats: z.number().nullable(),
        /**
         * The PBS item code for the medication.
         */
        pbsCode: z.string().nullable(),
        /**
         * The restrictions on the medication, if any.
         */
        restrictions: z.array(pbsRestrictionSchema).nullable(),
        /**
         * The streamline authority code for the medication, if any.
         */
        streamlineAuthorityCode: z.string().nullable(),
        /**
         * Whether the medication is a 60-day prescribable medicine.
         */
        is60DayMedicine: z.boolean(),
        /**
         * Whether the medication is on the 'List of Excluded Medicinal Items' (LEMI).
         *
         * NOTE: This currently applies to PBS medications only, hence it's inclusion in the PBS
         * snapshot.
         */
        isOnLemi: z.boolean(),
        /**
         * Whether the medication is on the 'List of Medicines for Brand Consideration' (LMBC).
         *
         * NOTE: This currently applies to PBS medications only, hence it's inclusion in the PBS
         * snapshot.
         */
        isOnLmbc: z.boolean(),
      })
      .nullable(),
    /**
     * Firestore timestamp representing the date and time the prescription was written.
     */
    prescribedDate: z.instanceof(Timestamp),
    /**
     * The quantity of the medication prescribed.
     */
    quantity: z.string(),
    /**
     * The number of repeats for the prescription.
     */
    repeats: z.string(),
    /**
     * The unique identifier for the prescription (also referred to as 'prescription number')
     */
    scriptId: z.string(),
    /**
     * Document ID of the user who wrote the prescription.
     *
     * NOTE: This is different from the 'prescriber' field, which is more of a snapshot of the
     * prescriber at the time of writing the prescription (since details can change).
     *
     * `Null` values indicate that the script was written before this field was added to the schema.
     */
    userDocumentId: z.string().nullable(),
  })
  .merge(z.object({ prescriber: prescriberSchema.nullable() }))
  .strict();

export const practiceDocumentSchema = z
  .object({
    default: z.boolean(),
    phoneNumber: z.string(),
    practiceName: z.string(),
    prefix: z.boolean(),
    userId: z.string(),
  })
  .merge(addressSchema);

export const practiceDocumentWithIdSchema = practiceDocumentSchema.merge(
  z.object({
    id: z.string(),
  }),
);

export const userSettingsSchema = z.object({
  isIndicationsDefaultExpanded: z.boolean(),
});

export const userDocumentSchema = z.object({
  // Qualifications is optional no matter how you spin it, and must be allowed as such
  fullName: z.string(),
  prescriberNumber: z.string().length(7),
  qualifications: z.string(),
  settings: userSettingsSchema,
  /** The date on which this user was notified (by email) of suspected fraudulent activity */
  fraudulentWarningDate: z.string().optional(),
});

export const userDocumentWithIdSchema = userDocumentSchema.merge(
  z.object({
    id: z.string(),
  }),
);

export type Address = z.infer<typeof addressSchema>;

/**
 * Raw Firestore user document
 */
export type UserDocument = z.infer<typeof userDocumentSchema>;

/**
 * Firestore user document with document ID
 */
export type UserDocumentWithId = z.infer<typeof userDocumentWithIdSchema>;

/**
 * Firestore practice document
 */
export type PracticeDocument = z.infer<typeof practiceDocumentSchema>;

/**
 * Firestore practice document with document ID
 */
export type PracticeDocumentWithId = z.infer<typeof practiceDocumentWithIdSchema>;

/**
 * Form values for the `PrescriberForm`
 */
export type PracticeFormValues = Omit<
  PracticeData,
  'default' | 'userId' | 'fullName' | 'prescriberNumber' | 'qualifications'
>;

export type PrescriberData = {
  fullName: string;
  prescriberNumber: string;
  qualifications: string;
};

export type PrescriptionFormValues = {
  /**
   * Read-only medication information. Set once a medication is selected.
   * If no medication is currently selected, this will be `null` (or if the selected medication
   * was invalidation/unverified)
   */
  medication: Medication | null;
  /**
   * Editable prescription information relating to the drug/medication.
   */
  drugData: DrugData;
  /**
   * Editable patient information.
   */
  patientData: PatientData;
  /**
   * The practice details associated with the prescription.
   */
  practiceData: Omit<PracticeDocument, 'userId'>;
  /**
   * Miscellaneous data associated with the prescription, e.g. script IDs, auth info, etc.
   */
  miscData: MiscData;
  /**
   * Read-only prescriber information. Account-level information.
   */
  prescriberData: PrescriberData;
};

/**
 * Represents a prescription written for a medication (including the associated document in
 * Firestore).
 */
export type Prescription = z.infer<typeof prescriptionSchema>;
