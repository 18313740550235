import { styled } from 'styled-components';

const StyledPractices = styled.div`
  .Practices__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .loader {
    margin-top: 4rem;
  }

  .table,
  .data-table {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-collapse: separate;
    border-radius: 6px;
    border-spacing: 0;
    -moz-border-radius: 6px;
    margin-top: 2rem;

    .tableHeader {
      background-color: #f9fafb;
      padding: 0.75rem 1.5rem;
      color: #48515b;
      font-size: 0.75rem;
      line-height: 0.85rem;
      text-align: left;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
      font-weight: 600;
      border-top: none;
      border-bottom: 1px solid #d1d6db;
    }

    .tableHeader:first-child {
      border-top-left-radius: 6px;
    }

    .tableHeader:last-child {
      border-top-right-radius: 6px;
    }

    .tableCell {
      padding: 0.8rem 1.5rem;
      font-size: 0.9rem;
      color: #48515b;
      border-bottom: 1px solid #d1d6db;
      vertical-align: middle;

      .default-btn {
        width: 8rem;
      }

      .btns {
        display: flex;
        gap: 0.8rem;

        .non-default {
          gap: 0.8rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .tableCellNone {
      height: 7rem;
      text-align: center;
      vertical-align: middle;
      padding: 0.8rem 1.5rem;
      font-size: 0.9rem;
      color: #5a6572;
    }

    .tableRowItems:last-child {
      .tableCell {
        border-bottom: none;
      }
    }
  }

  .table-none {
    .tableHeader {
      font-size: 0;
    }
  }

  .pagination {
    padding-top: 0.8rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  /* Landscape phones/portrait tables. Converts table to card view */
  @media (max-width: 700px) {
    .data-table {
      border: none;
      box-shadow: none;
      padding: 0;

      thead {
        left: -9999px;
        position: absolute;
        visibility: hidden;
      }

      tr {
        border-bottom: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        border-collapse: separate;
        border-radius: 6px;
        border-spacing: 0;
        -moz-border-radius: 6px;
      }

      .tableCell {
        position: relative;
        padding: 2.1rem 1rem 0.9rem 1rem;
        width: 100%;
        border: none;

        &:nth-of-type(3) {
          border-radius: 6px;
          width: 100%;
        }
      }

      .tableCell:before {
        position: absolute;
        top: 0;
        left: 0;
        content: attr(data-title);
        display: inline-block;
        height: 14px;
        width: calc(100% - 0.5rem);
        background-color: #f9fafb;
        padding: 0.2rem 0rem 0.2rem 0.5rem;
        color: #48515b;
        font-size: 0.75rem;
        line-height: 0.85rem;
        text-align: left;
        text-transform: uppercase;
        letter-spacing: 0.05rem;
        font-family: var(--font-stack-segoe-semibold);
        border-top: none;
      }

      .tableRowItems:last-child {
        border-radius: 6px;
        margin-bottom: 0;
      }
    }

    .TableFooter {
      justify-content: center;
    }

    .pagination {
      justify-content: center;
    }
  }

  /* Portrait phones, specifically smaller screens */
  @media (max-width: 360px) {
    .btns {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .default,
      .default--selected {
        margin-top: 1rem;
      }

      .delete {
        margin: 0;
      }
    }
  }
`;

export { StyledPractices };
