import { Restrictions } from '../Indications/Restrictions';
import DrugAutocomplete from '../DrugAutocomplete/DrugAutocomplete';
import { AlertState, SetAlertFunc } from '../utils/Alert/Alert';
import { PrescriptionFormValues } from '../../types/firestore';
import { Checkbox } from '@mantine/core';
import { handleCheckboxEnterPress } from '../../utils/formUtils';
import { UseFormReturnType } from '@mantine/form';
import classes from '../RxForm/RxForm.module.css';
import { TextInputWithCheckmark } from '../utils/TextInputWithCheckmark';
import { Medication } from '../../types/medication';
import { ChangeEvent, useCallback, useRef } from 'react';

type MedicationDetailsProps = {
  alerts: AlertState;
  setAlerts: SetAlertFunc;
  form: UseFormReturnType<PrescriptionFormValues>;
  isIndicationsDefaultExpanded: boolean;
  medications: Medication[];
  onSelectMedication: (medication: Medication) => void;
  onChangeIsPbsPrescription: (isPbsPrescription: boolean) => void;
  lemiComponent: React.ReactNode;
};

// Mostly created to help improve readability and size of parent RxForm component.
const MedicationDetails = ({
  alerts,
  setAlerts,
  form,
  isIndicationsDefaultExpanded,
  medications,
  onSelectMedication,
  onChangeIsPbsPrescription,
  lemiComponent,
}: MedicationDetailsProps) => {
  const { medication } = form.values;
  const dosageInputRef = useRef<HTMLInputElement>(null);

  const handleSelectMedication = useCallback(
    (medication: Medication) => {
      onSelectMedication(medication);
      dosageInputRef.current?.focus();
    },
    [onSelectMedication],
  );

  return (
    <div>
      {/* There must be enough info to identify the medicine, including form and strength, and 
      information regarding dosage, quantity, and repeats */}
      <DrugAutocomplete
        alerts={alerts}
        setAlerts={setAlerts}
        form={form}
        medications={medications}
        onSelectMedication={handleSelectMedication}
        lemiComponent={lemiComponent}
      />

      <TextInputWithCheckmark
        id="dosage"
        classNames={{ root: classes.formInput }}
        label="Dosage directions"
        {...form.getInputProps('drugData.dosage')}
        description={alerts.dosage?.message}
        inputWrapperOrder={['label', 'input', 'description', 'error']}
        isValid={form.isValid('drugData.dosage')}
        ref={dosageInputRef}
      />

      <Checkbox
        classNames={{
          root: classes.formInput,
          description: alerts.pbsRx?.kind === 'error' ? classes.warningDescription : '',
        }}
        label="PBS prescription"
        onKeyDown={handleCheckboxEnterPress}
        {...form.getInputProps('drugData.pbsRx', { type: 'checkbox' })}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          form.getInputProps('drugData.pbsRx').onChange(event);
          onChangeIsPbsPrescription(event.target.checked);
        }}
        description={alerts.pbsRx?.message}
      />

      <Restrictions
        medication={medication}
        isInitiallyExpanded={isIndicationsDefaultExpanded}
        isPbsPrescription={form.values.drugData.pbsRx}
      />

      <TextInputWithCheckmark
        classNames={{
          root: `${classes.formInput} ${classes.quantityInput}`,
          error: classes.quantityError,
          description: classes.quantityDescription,
        }}
        type="number"
        label="Quantity"
        {...form.getInputProps('drugData.quantity')}
        description={alerts.maxQuantity?.message}
        inputWrapperOrder={['label', 'input', 'description', 'error']}
        isValid={form.isValid('drugData.quantity')}
      />

      <TextInputWithCheckmark
        classNames={{
          root: `${classes.formInput} ${classes.repeatsInput}`,
          error: classes.quantityError,
          description: classes.repeatsDescription,
        }}
        type="number"
        label="Repeats"
        {...form.getInputProps('drugData.repeats')}
        description={alerts.maxRepeats?.message}
        inputWrapperOrder={['label', 'input', 'description', 'error']}
        isValid={form.isValid('drugData.repeats')}
      />
    </div>
  );
};

export default MedicationDetails;
