import { StyledFeatures } from './Features.styled';
import { Helmet } from 'react-helmet-async';
import lemiOptimised from '../../assets/optimised/lemi-lmbc.png';
import IndicationsExtract from './AppExtracts/IndicationsExtract';
import QuantityExtract from './AppExtracts/QuantityExtract';
import AuthorityExtract from './AppExtracts/AuthorityExtract';
import PracticesExtract from './AppExtracts/PracticesExtract';
import DashboardExtract from './AppExtracts/DashboardExtract';
import ScriptExtract from './AppExtracts/ScriptExtract';
import SignupButton from '../utils/SignupButton/SignupButton';
import useHashScroll from '../../hooks/useHashScroll';

const Features = () => {
  useHashScroll();
  return (
    <>
      <Helmet>
        <title>Features · OptomRx</title>
        <meta
          name="description"
          content="Discover some of the unique features that OptomRx has to offer, including  standout PBS support."
        />
        <link rel="canonical" href="/features" />
      </Helmet>
      <StyledFeatures>
        <div className="Features-container">
          <section className="Features__intro">
            <div className="content-container">
              <h2 className="Features__title">Features</h2>
              <p className="subtitle">
                Learn about the key features that make OptomRx the complete solution for writing
                therapeutic prescriptions.
              </p>
            </div>
          </section>

          <section
            className="Features__pbs"
            aria-labelledby="description__title"
            id="real-time-pbs"
          >
            <h3 id="description__title" className="section__title">
              Real-time PBS data
            </h3>
            <p className="pbs-subtitle">Available for all PBS scripts</p>
            <div className="content-container pbs-container indications-container">
              <div className="description">
                <h4 className="description__title">Instant PBS availability and indications</h4>
                <p className="description__text">
                  The moment you select a medication, OptomRx will show you whether it is eligible
                  for PBS prescribing. If the medication is restricted or requires authority, you
                  can optionally view the indications for it&apos;s use under the PBS.
                </p>
              </div>
              <div className="indicationsDOMBox pbsDOMBox">
                <IndicationsExtract />
              </div>
            </div>

            <div className="content-container pbs-container quantityRepeats-container">
              <div className="quantityDOMBox pbsDOMBox">
                <QuantityExtract />
              </div>
              <div className="description">
                <h4 className="description__title">Avoid mistakes in prescribing</h4>
                <p className="description__text">
                  Every PBS medication has maximum allowable quantity and repeats. Using up-to-date
                  PBS data, OptomRx shows you these values for any selected PBS medication, making
                  any prescribing errors less likely.
                </p>
              </div>
            </div>

            <div className="content-container pbs-container authority-container">
              <div className="description">
                <h4 className="description__title">
                  Save time for those less common authority scripts
                </h4>
                <p className="description__text">
                  When a streamline authority code is available, it will be supplied for you,
                  automatically. For those medications requiring manual authority codes, OptomRx
                  will advise you where to apply for authority.
                </p>
              </div>
              <div className="authorityDOMBox pbsDOMBox">
                <AuthorityExtract />
              </div>
            </div>
          </section>

          <section
            className="Features__lemi"
            aria-labelledby="lemi__title"
            id="active-ingredient-prescribing"
          >
            <div className="content-container lemi-container">
              <div className="lemi__screenshots">
                <img
                  src={lemiOptimised}
                  loading="lazy"
                  alt="Front cover of LEMI and LMBC guides for active ingredient prescribing"
                />
              </div>
              <div className="lemi__description description">
                <h3 id="lemi__title" className="section__title">
                  Active ingredient prescribing
                </h3>
                <p className="lemi__text">
                  Guidelines now exist for all prescribers in Australia, requiring active ingredient
                  prescribing in many cases. Several ophthalmic medications however belong to the
                  List of Excluded Medicinal Items (LEMI) or the List of Medicines for Brand
                  Consideration (LMBC), where active ingredient prescribing may not apply.
                </p>
                <p className="lemi__text">
                  OptomRx identifies which items belong to the LEMI or LMBC, and automatically
                  adjusts your prescriptions to meet relevant guidelines. You can find out if any
                  LEMI/LMBC adjustments have been made while writing your scripts, and overwrite
                  this if necessary.
                </p>
              </div>
            </div>
          </section>

          <section
            className="Features__practices"
            aria-labelledby="practices__title"
            id="multiple-practices"
          >
            <div className="content-container practices-container">
              <div className="practices__description description">
                <h3 id="practices__title" className="section__title">
                  Multiple practices
                </h3>
                <p className="practices__text">
                  Whether you work in a single practice, or locum all across Australia - OptomRx
                  supports as many practices as you need. Practice details can be modified, added,
                  or removed with ease. Simply select whichever practice details you wish to use
                  when you write a prescription. Simplify things further with default selection for
                  your most common practice location.
                </p>
              </div>
              <div className="practicesDOMBox">
                <PracticesExtract />
              </div>
            </div>
          </section>

          <section
            className="Features__represcribe"
            aria-labelledby="represcribe__title"
            id="instant-represcribe"
          >
            <div className="content-container represcribe-container">
              <div className="represcribe__description description">
                <h3 id="represcribe__title" className="section__title">
                  Instant re-prescribe
                </h3>
                <p className="represcribe__text">
                  In practice, you&apos;ll be reaching for the same scripts over and over; why waste
                  time re-writing the same script every time? With OptomRx, you are able to save any
                  script as a &apos;favourite&apos;.
                </p>
                <p className="represcribe__text">
                  These favourites contain specific medication details, e.g. ingredient, quantity,
                  repeats, dosage, etc. You can re-prescribe these favourites with a single click.
                  Just add patient details, and the script is done. Quick and easy.{' '}
                </p>
              </div>
              <div className="represcribeDOMBox">
                <DashboardExtract />
              </div>
            </div>
          </section>

          <section className="Features__data" aria-labelledby="data__title">
            <div className="content-container data-container">
              <div className="dataDOMBox">
                <ScriptExtract />
              </div>
              <div className="data__description description">
                <h3 id="data__title" className="section__title">
                  Prioritising patient privacy
                </h3>
                <p className="data__text">
                  <strong>
                    OptomRx is committed to ensuring the privacy of patient information.
                  </strong>{' '}
                  Only non-identifiable medication and PBS details are stored when a prescription is
                  saved. For authority scripts, prescriber copies are conveniently provided in the
                  printable template for your records.
                </p>
                <p className="data__text">
                  This commitment to not retaining patient data underscores our dedication to
                  patient safety and defines OptomRx as a prescription writing tool, rather than a
                  prescription database.
                </p>
              </div>
            </div>
          </section>

          <section className="Features__action" aria-labelledby="action__title">
            <div className="content-container action-container">
              <p className="subtitle action__subtitle">Try it for yourself</p>
              <h3 id="action__title" className="section__title">
                OptomRx is completely free to use
              </h3>
              <SignupButton />
            </div>
          </section>
        </div>
      </StyledFeatures>
    </>
  );
};

export default Features;
