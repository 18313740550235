import { useState } from 'react';
import { useAuthContext } from './useAuthContext';
import { useErrorHandling } from './useErrorHandling';
import { FirebaseError } from 'firebase/app';
import { logError } from '../utils/logError';

// Custom hook to handle user signing in

export const useSignup = () => {
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);
  const { signup: authContextSignup } = useAuthContext();
  const { handleLoginSignupError } = useErrorHandling();

  const signup = async (signupFormValues: {
    email: string;
    password: string;
    fullName: string;
    prescriberNumber: string;
    qualifications: string;
  }) => {
    setError(null);
    setIsPending(true);
    const { email, password, fullName, prescriberNumber, qualifications } = signupFormValues;

    try {
      await authContextSignup({
        email,
        password,
        fullName,
        prescriberNumber,
        qualifications,
      });
      setIsPending(false);
    } catch (err) {
      setIsPending(false);
      if (err instanceof FirebaseError) {
        const errorMsg = handleLoginSignupError(err.code);
        setError(errorMsg);
      } else {
        logError(err);
        setError('An unknown error occurred during sign up');
      }
    }
  };

  return { error, isPending, signup, setError };
};
