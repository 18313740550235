import { useParams, Link } from 'react-router-dom';
import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import './ViewScript.css';
import { formatPrescribedMedication } from '../../hooks/useFormatting';
import Spinner from '../utils/Spinner/Spinner';
import { Helmet } from 'react-helmet-async';
import { StyledViewScript } from './ViewScript.styled';
import { Prescription, PrescriptionFormValues, prescriptionSchema } from '../../types/firestore';
import { useDocument } from '../../hooks/useDocument';
import { useHandleFetchError } from '../../hooks/useHandleFetchError';
import type { User } from 'firebase/auth';
import { Button, useMantineTheme } from '@mantine/core';
import { IconStar, IconArrowForward } from '@tabler/icons-react';
import { AddFavouriteModal } from './AddFavouriteModal';
import { useDisclosure } from '@mantine/hooks';
import {
  initialPrescriptionFormValues,
  usePrescriptionInitialValues,
} from '../../context/PrescriptionInitialValuesContext';
import { format } from 'date-fns';
import { DeleteFavouriteModal } from '../Favourites/DeleteFavouriteModal';
import { updateDoc, doc } from 'firebase/firestore';
import toast from 'react-hot-toast';
import { db } from '../../firebase/config';
import { logError } from '../../utils/logError';
import { PRESCRIPTIONS_COLLECTION } from '../../entities/collections';

type ViewScriptProps = {
  user: User;
};

function formatPrescriptionDate(prescription: Prescription) {
  const prescribedDate = prescription.prescribedDate.toDate();
  return format(prescribedDate, 'MMM d, yyyy, h:mm a');
}

/**
 * Convert a prescription to the form values expected by the prescription form.
 *
 * NOTE: Many prescription values are not converted to form values. These include:
 * - Patient data (not saved in prescriptions)
 * - Prescriber data (set automatically when writing a prescription)
 * - Practice data (set automatically when writing a prescription)
 * - Misc data (should not include auth info as it's subject to change)
 * - `medication` (needs to be fetched)
 */
export function convertPrescriptionToFormValues(prescription: Prescription) {
  return {
    ...initialPrescriptionFormValues,
    medication: null,
    drugData: {
      activeIngredient: prescription.activeIngredient,
      brandName: prescription.brandName,
      quantity: prescription.quantity,
      repeats: prescription.repeats,
      dosage: prescription.dosage,
      verified: false,
      indications: '',
      substitutePermitted: prescription.isBrandSubstitutionPermitted,
      brandOnly: prescription.isBrandNameOnly,
      includeBrand: prescription.isBrandNameIncluded,
      pbsRx: prescription.isPbsPrescription,
      compounded: prescription.isCompoundingRequired,
      authRequired: prescription.isAuthoritySelected,
      medicationId: prescription.medicationId,
    },
    miscData: {
      ...initialPrescriptionFormValues.miscData,
      age: prescription.patientAge ?? '',
      authCode: prescription.authorityCode ?? '',
      justification: prescription.clinicalJustification ?? '',
      prevAuth: prescription.hasPreviouslyReceivedAuthority ?? false,
    },
  } satisfies PrescriptionFormValues;
}

const ViewScript = ({ user }: ViewScriptProps) => {
  const { id } = useParams();
  const {
    data: prescription,
    isLoading,
    error,
  } = useDocument(`prescriptions/${id}`, prescriptionSchema);
  useHandleFetchError(error);
  const theme = useMantineTheme();
  const { setInitialValues } = usePrescriptionInitialValues();

  const [
    isAddFavouriteModalVisible,
    { open: openAddFavouriteModal, close: closeAddFavouriteModal },
  ] = useDisclosure(false);

  const [
    isDeleteFavouriteModalVisible,
    { open: openDeleteFavouriteModal, close: closeDeleteFavouriteModal },
  ] = useDisclosure(false);

  const deleteFavourite = async (favouriteToRemove: Prescription) => {
    try {
      await updateDoc(doc(db, PRESCRIPTIONS_COLLECTION, favouriteToRemove.scriptId), {
        isFavourite: false,
        favouriteName: null,
      });
      closeDeleteFavouriteModal();
      toast.success('Deleted favourite');
    } catch (err) {
      logError(err);
      toast.error('An error occurred while deleting the favourite');
    }
  };

  return (
    <>
      <Helmet>
        <title>View script · OptomRx</title>
        <meta
          name="description"
          content="View medication and PBS details for the selected script. Patient data is not retained."
        />
      </Helmet>
      <ContentContainer>
        <StyledViewScript>
          <PageHeader title={`Script #${id}`} />
          {isLoading && <Spinner />}
          {prescription && (
            <>
              <div className="Script__info">
                <div className="Script__medication">
                  <div className="Script__title Script__title--medication">Medication details</div>
                  <div className="Script__info--section Script__drug">
                    {formatPrescribedMedication(prescription)}
                  </div>
                  {prescription.isCompoundingRequired && (
                    <div className="Script__info--section Script__compounded">To be compounded</div>
                  )}
                  <div className="Script__info--section Script__substitute">{`${
                    prescription.isBrandSubstitutionPermitted
                      ? 'Brand substitution allowed'
                      : 'Brand substitution not permitted'
                  }`}</div>
                  <div className="Script__info--section Script__dosage">
                    Dosage: {prescription.dosage}
                  </div>
                  <div className="Script__info--section Script__quantity">
                    Quantity: {prescription.quantity}
                  </div>
                  <div className="Script__info--section Script__repeats">
                    Repeats: {prescription.repeats}
                  </div>
                </div>

                <div className="Script__pbs">
                  <div className="Script__title Script__title--pbs">PBS details</div>
                  <div className="Script__info--section Script__pbs">{`${
                    prescription.isPbsPrescription ? 'PBS prescription' : 'Non-PBS prescription'
                  }`}</div>

                  {prescription.pbsSnapshot?.isAuthorityRequired && (
                    <div className="Script__authority">
                      <div className="Script__info--section Script__authCode">
                        Authority code: {prescription.authorityCode}
                      </div>
                      <div className="Script__info--section Script__authNum">
                        Authority Rx No: {prescription.authorityPrescriptionNumber}
                      </div>
                      <div className="Script__info--section Script__indications">
                        Clinical justification for use of item: {prescription.clinicalJustification}
                      </div>
                    </div>
                  )}
                  <div className="Script__info--section Script__date">
                    Prescribed: {formatPrescriptionDate(prescription)}
                  </div>
                </div>
              </div>

              <div className="PrescriberForm__btns">
                <Button
                  component={Link}
                  variant="primary"
                  leftSection={<IconArrowForward width={20} />}
                  to="/prescription/new?isReprescribing=true"
                  onClick={() => setInitialValues(convertPrescriptionToFormValues(prescription))}
                >
                  Re-prescribe
                </Button>

                <Button
                  variant="primary"
                  leftSection={
                    <IconStar
                      width={20}
                      color={prescription.isFavourite ? theme.colors.yellow[5] : '#FFFFFF'}
                      fill={prescription.isFavourite ? theme.colors.yellow[5] : 'transparent'}
                    />
                  }
                  onClick={
                    prescription.isFavourite ? openDeleteFavouriteModal : openAddFavouriteModal
                  }
                >
                  {prescription.isFavourite ? 'Remove from favourites' : 'Add to favourites'}
                </Button>
              </div>
            </>
          )}

          {prescription ? (
            <AddFavouriteModal
              isOpen={isAddFavouriteModalVisible}
              onClose={closeAddFavouriteModal}
              user={user}
              prescription={prescription}
            />
          ) : null}
          {prescription ? (
            <DeleteFavouriteModal
              isOpen={isDeleteFavouriteModalVisible}
              onClose={closeDeleteFavouriteModal}
              favourite={prescription}
              onDelete={() => deleteFavourite(prescription)}
            />
          ) : null}
        </StyledViewScript>
      </ContentContainer>
      <p className="bottom-text">
        OptomRx prioritises patient privacy by not storing any personal details. Only medication
        information is retained for review.
      </p>
    </>
  );
};

export default ViewScript;
