import { useEffect, useState } from 'react';
import { onSnapshot, query as createQuery, collection, QueryConstraint } from 'firebase/firestore';
import { db } from '../firebase/config';
import { z } from 'zod';
import { logError } from '../utils/logError';

type Options = {
  /**
   * Whether to include the document IDs in the data. Optional, defaults to false.
   */
  includeDocumentIds?: boolean;
  /**
   * A function that returns an array of query constraints to apply to the collection.
   *
   * Should be wrapped in `useCallback` if it depends on any variables, e.g.
   * @example
   * getQueryConstraints: useCallback(() => [where('userDocumentId', '==', user.uid)], [user.uid]),
   *
   */
  getQueryConstraints?: () => QueryConstraint[];
  /**
   * Whether the query is enabled. Defaults to true.
   */
  isEnabled?: boolean;
};

/**
 * A hook for fetching and listening to multiple documents in a Firestore collection.
 *
 * @template S The Zod schema type for the expected documents in the collection.
 * @param collectionPath The path to the collection in Firestore.
 * @param schema The Zod schema to validate the data of the documents in the collection.
 * @param options Optional. Configuration options for the hook.
 * @returns An object containing the collection data, loading state, and any error.
 *
 * @example
 * // Usage with a specific schema for the collection data and dynamic query constraints
 * const { data, isLoading, error } = useCollection(
 *    'practices',
 *    practiceDocumentSchema,
 *    {
 *      includeDocumentIds: true,
 *      getQueryConstraints: useCallback(() => [where('uid', '==', user.uid)], [user.uid]),
 *    }
 * );
 */
export function useCollection<S extends z.ZodType>(
  collectionPath: string,
  schema: S,
  options: Options = {
    includeDocumentIds: false,
  },
) {
  type DataType = z.infer<S>;
  const [data, setData] = useState<DataType[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);

  const { includeDocumentIds, getQueryConstraints, isEnabled = true } = options;

  useEffect(() => {
    if (!isEnabled) {
      setIsLoading(false);
      return;
    }

    const collectionRef = collection(db, collectionPath);
    const queryConstraints = getQueryConstraints ? getQueryConstraints() : [];
    const query = createQuery(collectionRef, ...queryConstraints);
    const unsubscribe = onSnapshot(
      query,
      (snapshot) => {
        const documents = snapshot.docs.map((doc) => {
          let docData = doc.data();
          if (includeDocumentIds) {
            docData = { ...docData, id: doc.id };
          }
          return docData;
        });
        try {
          const validatedData = z.array(schema).parse(documents);
          setData(validatedData);
        } catch (error) {
          logError(error);
          setError(error); // Handle Zod validation errors and any other errors
        } finally {
          setIsLoading(false); // Always set loading to false after processing
        }
      },
      (error) => {
        logError(error);
        setError(error); // Handle Firestore errors
        setIsLoading(false);
      },
    );

    return () => unsubscribe();
  }, [collectionPath, getQueryConstraints, includeDocumentIds, schema, isEnabled]);

  return { data, isLoading, error };
}
