import { Skeleton, SkeletonProps } from '@mantine/core';
import classes from './Skeletons.module.css';

export type TextInputSkeletonProps = {
  /**
   * Width of the input skeleton. Optional. Default CSS styles will limit max-width to 200px,
   * however there is no minimum width set (i.e. width = 100% by default)
   */
  inputWidth?: SkeletonProps['width'];
  /**
   * Width of the label skeleton. Optional. Default width = 75.
   */
  labelWidth?: SkeletonProps['width'];
};

/**
 * A skeleton component that mimics the layout of a text form input.
 */
export function TextInputSkeleton({
  labelWidth = 75,
  inputWidth,
}: {
  inputWidth?: SkeletonProps['width'];
  labelWidth?: SkeletonProps['width'];
}) {
  return (
    <div className={classes.inputSkeletonContainer}>
      <Skeleton height={12} width={labelWidth} className={classes.inputLabelSkeleton} />
      <Skeleton height={42} width={inputWidth} className={classes.inputSkeleton} />
    </div>
  );
}
