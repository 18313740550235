import { StyledFavourites } from './Favourites.styled';
import Spinner from '../utils/Spinner/Spinner';
import { useCallback, useState } from 'react';
import { doc, updateDoc, where } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { Link } from 'react-router-dom';
import { formatPrescribedMedication } from '../../hooks/useFormatting';
import type { User } from 'firebase/auth';
import { Prescription, prescriptionSchema } from '../../types/firestore';
import { useHandleFetchError } from '../../hooks/useHandleFetchError';
import toast from 'react-hot-toast';
import { Button } from '@mantine/core';
import { logError } from '../../utils/logError';
import { DeleteFavouriteModal } from './DeleteFavouriteModal';
import { useCollection } from '../../hooks/useCollection';
import { usePrescriptionInitialValues } from '../../context/PrescriptionInitialValuesContext';
import { convertPrescriptionToFormValues } from '../ViewScript/ViewScript';
import { PRESCRIPTIONS_COLLECTION } from '../../entities/collections';

type FavouritesProps = {
  user: User;
};

const Favourites = ({ user }: FavouritesProps) => {
  const { data, isLoading, error } = useCollection(PRESCRIPTIONS_COLLECTION, prescriptionSchema, {
    getQueryConstraints: useCallback(
      () => [where('userDocumentId', '==', user.uid), where('isFavourite', '==', true)],
      [user.uid],
    ),
  });
  const { setInitialValues } = usePrescriptionInitialValues();

  // Initialised here to avoid reference errors in the modal when pulling selected script data
  const [selectedScript, setSelectedScript] = useState<Prescription | null>(null);

  useHandleFetchError(error);

  const favourites = data ?? [];

  // Remove favourite from firestore database
  const deleteFavourite = async (favouriteToRemove: Prescription) => {
    try {
      await updateDoc(doc(db, PRESCRIPTIONS_COLLECTION, favouriteToRemove.scriptId), {
        isFavourite: false,
        favouriteName: null,
      });
      setSelectedScript(null);
      toast.success('Deleted favourite');
    } catch (err) {
      logError(err);
      setSelectedScript(null);
      toast.error('An error occurred while deleting the favourite');
    }
  };
  if (isLoading) {
    return (
      <StyledFavourites>
        <Spinner />
      </StyledFavourites>
    );
  }

  if (error) {
    return (
      <StyledFavourites>
        <ul className="fav-list fav-list--none">
          <div className="list-header">Favourites</div>
          <li className="fav-item fav-item--none">No favourites added yet</li>
        </ul>
      </StyledFavourites>
    );
  }

  return (
    <StyledFavourites>
      <h2 className="Favourites__title">Favourites</h2>
      {favourites.length > 0 ? (
        <>
          <ul className="fav-list">
            <li className="first-list-item">
              <span>Script name</span>
              <span className="actions-span">Actions</span>
            </li>
            {favourites.map((fav) => (
              <li key={fav.scriptId} className="fav-item">
                <div className="item-name">
                  <span className="cell-title">Script name</span>
                  <span className="item-content">
                    {fav.favouriteName ?? formatPrescribedMedication(fav)}
                  </span>
                </div>
                <div className="actions">
                  <span className="cell-title">Actions</span>
                  <div className="btns">
                    <Button
                      component={Link}
                      className="prescribe"
                      variant="primary"
                      to="/prescription/new?isReprescribing=true"
                      size="xs"
                      onClick={() => setInitialValues(convertPrescriptionToFormValues(fav))}
                    >
                      Prescribe
                    </Button>
                    <Button
                      variant="transparent"
                      size="xs"
                      onClick={() => {
                        setSelectedScript(fav);
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <ul className="fav-list fav-list--none">
          <div className="list-header">Favourites</div>
          <li className="fav-item fav-item--none">
            Favourites can be added by clicking on an individual script in the &apos;My
            scripts&apos; page
          </li>
        </ul>
      )}
      {selectedScript ? (
        // FIXME: This implemenation means the modal won't fade in properly. It needs to be rendered
        // all the time, then have its visibility toggled, to allow for the fade in effect.
        // To much hassle to refactor for now.
        <DeleteFavouriteModal
          isOpen
          onClose={() => setSelectedScript(null)}
          onDelete={() => deleteFavourite(selectedScript)}
          favourite={selectedScript}
        />
      ) : null}
    </StyledFavourites>
  );
};

export default Favourites;
