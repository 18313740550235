import Header from './components/Header/Header';
import GlobalStyles from './components/utils/globalStyles';
import { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import { Signup } from './components/Signup/Signup';
import Login from './components/Login/Login';
import { useAuthContext } from './hooks/useAuthContext';
import Dashboard from './components/Dashboard/Dashboard';
import { Practices } from './components/Practices/Practices';
import './App.css';
import EditPractice from './components/EditPractice/EditPractice';
import AddPractice from './components/AddPractice/AddPractice';
import Main from './components/Main/Main';
import Scripts from './components/Scripts/Scripts';
import ViewScript from './components/ViewScript/ViewScript';
import Settings from './components/Settings/Settings';
import ResetPassword from './components/ResetPassword/ResetPassword';
import { StyledApp } from './App.styled';
import Home from './components/Home/Home';
import HomeFooter from './components/Footer/HomeFooter';
import PrivacyPolicy from './components/Policies/PrivacyPolicy';
import Terms from './components/Policies/Terms';
import Features from './components/Features/Features';
import { useGoogleAPI } from './hooks/useGoogleAPI';
import About from './components/About/About';
import FAQ from './components/FAQ/FAQ';
import { useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { PrescriptionRoutes } from './components/PrescriptionRoutes/PrescriptionRoutes';
import NotFound from './components/NotFound/NotFound';
import { MantineProvider } from '@mantine/core';
import { theme } from './styles/theme';
import { Admin } from './components/Admin/Admin';

export type PageName =
  | 'form'
  | 'login'
  | 'signup'
  | 'scripts'
  | 'settings'
  | 'practices'
  | 'features'
  | 'home';

const App = () => {
  // Can use the user state to conditionally render or redirect routes (logged in vs out for example)
  const { user, authIsReady, isAdmin } = useAuthContext();
  const { loadGoogleAPI, googleLoaded } = useGoogleAPI();
  const { pathname } = useLocation();

  // Ensure the window is scrolled to the top when changing any routes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Only load google API services when the user is logged in
  useEffect(() => {
    if (user) {
      loadGoogleAPI();
    }
  }, [googleLoaded, loadGoogleAPI, user]);

  return (
    <MantineProvider theme={theme}>
      <StyledApp className="App">
        <GlobalStyles />
        <Toaster position="bottom-center" />
        {/* Use a large full-screen loader instead of `null` fallback? */}
        {authIsReady ? (
          <>
            <Header />
            <Main isLoggedIn={user != null}>
              <Routes>
                <Route path="/" element={user ? <Navigate to="/dashboard" /> : <Home />} />

                <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                <Route path="/terms-of-service" element={<Terms />} />

                <Route path="/features" element={<Features />} />

                <Route path="/faq" element={<FAQ />} />

                <Route path="/about" element={<About />} />

                <Route path="/admin" element={isAdmin ? <Admin /> : <Navigate to="/dashboard" />} />

                <Route
                  path="/dashboard"
                  element={user ? <Dashboard user={user} /> : <Navigate to="/login" />}
                />

                <Route
                  path="/settings/*"
                  element={user ? <Settings user={user} /> : <Navigate to="/login" />}
                />

                <Route
                  path="/prescription/*"
                  element={
                    <>
                      {user ? (
                        <PrescriptionRoutes user={user} googleLoaded={googleLoaded} />
                      ) : (
                        <Navigate to="/login" />
                      )}
                    </>
                  }
                />

                <Route path="/signup" element={user ? <Navigate to="/dashboard" /> : <Signup />} />

                <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />

                <Route
                  path="/edit-practice/:id"
                  element={
                    user ? (
                      <EditPractice googleLoaded={googleLoaded} user={user} />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />

                <Route
                  path="/add-practice"
                  element={
                    user ? (
                      <AddPractice googleLoaded={googleLoaded} user={user} />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />

                <Route
                  path="/practices"
                  element={user ? <Practices user={user} /> : <Navigate to="/login" />}
                />

                <Route
                  path="/scripts"
                  element={user ? <Scripts user={user} /> : <Navigate to="/login" />}
                />

                <Route
                  path="/scripts/:id"
                  element={user ? <ViewScript user={user} /> : <Navigate to="/login" />}
                />

                <Route path="/reset-password" element={<ResetPassword />} />

                <Route path="*" element={<NotFound />} />
              </Routes>
            </Main>
            {!user && <HomeFooter />}
          </>
        ) : null}
      </StyledApp>
    </MantineProvider>
  );
};

export default App;
